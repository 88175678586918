<template>
  <div class="w-full overflow-content">
    <div
      class="flex flex-col items-center justify-center font-opensans w-full bg-maizal-bg-yellow mt-4"
    >
      <div
        class="flex justify-center items-center border border-maizal-yellow rounded-full w-full max-w-xxs my-4"
      >
        <div class="flex justify-center w-full">
          <div
            v-for="(tab, index) in tab_content"
            :key="tab.title"
            class="rounded-full border-r text-center py-2 w-full cursor-pointer"
            :class="`bg-${
              selectedIndex === index ? 'maizal-yellow' : 'transparent'
            }`"
            @click="toggleTab(tab.title, index)"
          >
            <div class="text-center font-brandon">
              {{ tab.title }}
            </div>
          </div>
        </div>
      </div>
      <Pickup
        v-if="isModalPickupShowing"
        orderType="pickup"
        :service="service"
        :geoCoder="geoCoder"
        @close="
          isModalPickupShowing = false;
          unlockBody();
        "
        @closeBoth="close()"
        @showAddon="showAddon"
      />
      <Delivery
        v-if="geoCoder && isModalDeliveryShowing"
        orderType="delivery"
        :service="service"
        :geoCoder="geoCoder"
        @close="
          isModalDeliveryShowing = false;
          unlockBody();
        "
        @showAddon="showAddon"
        @closeBoth="close()"
      />
    </div>
  </div>
</template>
<script>
import geoLocationHelper from "@/_utils/geo-location";
import { userMethods, authComputed, userComputed } from "@/state/helpers";
import { Loader } from "@googlemaps/js-api-loader";
import appConfig from "@/app.config.json";
export default {
  components: {
    Pickup: () => import("@/components/orderType/pickup.vue"),
    Delivery: () => import("@/components/orderType/delivery.vue"),
  },
  props: {
    showing: {
      required: false,
      type: Boolean,
    },
    fromBag: {
      type: Boolean,
    },
  },
  computed: {
    ...authComputed,
    ...userComputed,
  },
  data() {
    return {
      service: null,
      geoCoder: null,
      isModalCurbsideShowing: false,
      isModalPickupShowing: false,
      isModalDeliveryShowing: false,
      loading: false,
      selectedIndex: 0,
      tab_content: [
        {
          title: "pickup",
        },
        {
          title: "delivery",
        },
      ],
    };
  },
  methods: {
    ...userMethods,
    close() {
      this.$emit("close");
    },
    toggleTab(ordertype, index) {
      this.selectedIndex = index;
      if (ordertype === "pickup") {
        this.showModalPickup();
      } else {
        this.showModalDelivery();
      }
    },
    showAddon(item) {
      console.log("item", item);
      this.$emit("showAddon", item);
    },
    saveBag() {
      this.$emit("saveBag");
    },
    showModalCurbside() {
      this.isModalCurbsideShowing = !this.isModalCurbsideShowing;
    },
    showModalPickup() {
      this.isModalDeliveryShowing = false;
      this.isModalPickupShowing = true;
    },
    showModalDelivery() {
      this.isModalPickupShowing = false;
      this.isModalDeliveryShowing = true;
    },
    lockBody() {
      const body = document.body;
      body.style.height = "100vh";
      body.style.overflowY = "hidden";
      this.loading = false;
    },
    unlockBody() {
      const body = document.body;
      body.style.height = "";
      body.style.overflowY = "";
    },
  },
  mounted() {
    this.loading = true;
    const options = {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: 0,
    };
    geoLocationHelper
      .getPosition(options)
      .then((position) => {
        this.setLatitude(position.coords.latitude);
        this.setLongitude(position.coords.longitude);
      })
      .catch((err) => {
        console.error(err.message);
      });

    const loader = new Loader({
      apiKey: appConfig.googleAPIKey,
      libraries: ["places"],
    });
    loader
      .load()
      .then(async (google) => {
        this.service = new google.maps.places.AutocompleteService();
        this.geoCoder = new google.maps.Geocoder();
      })
      .catch((error) => {
        console.log(error);
      });
    this.isModalPickupShowing = true;
  },
};
</script>
