<template>
  <BagIcon @toggle="toggleBag" :isEmpty="isBagEmpty" :bagSize="bagSize">
    <Transition name="fade">
      <div
        ref="bag"
        v-if="isBagOpen"
        @click.self="closeBag"
        class="
          backdrop-filter backdrop-blur-sm
          w-full
          h-screen
          absolute
          z-30
          top-0
          left-0
          bg-semi-75
        "
      >
        <div
          class="
            ml-auto
            bg-white
            text-sm
            font-normal
            max-h-screen
            overflow-y-auto
            xl:max-w-md
            lg:max-w-md
            md:max-w-sm
            w-full
            h-screen
            overflow-content
            fade
          "
        >
          <SideBarHeader @closePage="closeBag" :isBag="true"/>
          <BagGuest @closeBag="closeBag" />
        </div>
      </div>
    </Transition>
  </BagIcon>
</template>
<script>
import { bagComputed, bagMethods } from "@/state/helpers";

export default {
  components: {
    SideBarHeader: () => import("../sideBar/SideBarHeader.vue"),
    BagIcon: () => import("./BagIcon.vue"),
    BagGuest: () => import("../bag/BagGuest.vue"),
  },
  props: {
    username: {
      type: String,
    },
    location: {
      type: String,
    },
    loggedIn: {
      type: Boolean,
    },
  },
  data() {
    return {
      page: "",
      animated: false,
    };
  },
  computed: {
    ...bagComputed,
    meal:function(){return Object.assign({}, this.selectedMeals)}
  },
  methods: {
    ...bagMethods,
    clickHandler() {
      const self = this
      self.animated = true
      setTimeout(() => {
        self.animated = false
      }, 1000)
    },
    closeBag() {
      this.setBagOpen(false);
      this.unlockBody();
    },
    toggleBag() {
      this.setBagOpen(!this.isBagOpen);
      this.isBagOpen ? this.lockBody() : this.unlockBody();
    },
    lockBody() {
      const body = document.body;
      body.style.height = "100vh";
      body.style.overflowY = "hidden";
    },
    unlockBody() {
      const body = document.body;
      body.style.height = "";
      body.style.overflowY = "";
    },

  },
  mounted() {
    this.setBagOpen(false);
  },

  watch:{
       meal:function(newVal,old) {

        if(Object.keys(newVal).length > Object.keys(old).length){
            this.clickHandler();

        }
       }
    }
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s;
}
.fade-enter,
.fade-leave-to {
  opacity: 1;
  transform: translateX(100%);
  transition: 0.25s all ease-in-out;
}
.fade-move{
    transition: transform 1s;
}
.base-btn {
  padding: 1rem 3rem;
  background: #a0f0c0;
  font-size: 1rem;
  border-radius: 3px;
  box-shadow: 3px 3px #555;
  &:hover {
    background: #f0a0d0;
    box-shadow: 3px 3px #333;
  }
  &:focus {
    outline: none;
  }
}

.shake {
  animation: wobble 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
