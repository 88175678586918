<template>
  <div class="font-light w-full bg-maizal-bg-yellow">
    <p class="text-base font-bold text-maizal-black">choose day</p>
    <div class="mb-4 w-full flex" v-if="weekdays">
      <select
        class="cursor-pointer border-b-0.5 border-maizal-icon hover:bg-light-yellow w-full py-3 text-left font-bold font-opensans bg-maizal-bg-yellow"
        v-model="initialPickUpDay"
        :onchange="setDay()"
      >
        <option disabled value="">
          {{ initialPickUpDay }}
        </option>
        <option :key="day" v-for="day in weekdays" :value="day">
          {{ day }}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
import timeHelper from "@/_utils/time";
import { userComputed, userMethods } from "@/state/helpers";
import moment from "moment";
export default {
  props: {
    vendor: {
      type: Object,
    },
    advanceOrderDays: {
      type: Number,
    },
    earliestReadyDay: {
      type: String,
    },
  },
  data() {
    return {
      initialPickUpDay: null,
      weekdays: null,
      swiperOption: {
        slidesPerColumnFill: "row",
        direction: "horizontal",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          hideOnClick: true,
        },
        on: {
          slideChange: () => {
            this.initialPickUpDay =
              this.weekdays[this.$refs.daySwiper.swiperInstance.realIndex];
            this.setSelectedDay(this.initialPickUpDay);
          },
        },
      },
    };
  },
  computed: {
    ...userComputed,
  },
  methods: {
    ...userMethods,
    setDay() {
      this.setSelectedDay(this.initialPickUpDay);
    },
  },
  created() {
    this.setSelectedDay("");
  },
  async mounted() {
    if (Object.keys(this.location).length > 0) {
      this.weekdays = await timeHelper.getDayAndDate(
        this.location.advanceorderdays, this.location.id
      );
    }
    if (this.weekdays) {
      if (
        moment(this.earliestReadyDay, "dddd MMMM Do, YYYY").isAfter(
          moment(this.weekdays[0], "dddd MMMM Do, YYYY")
        )
      ) {
        this.initialPickUpDay = this.earliestReadyDay;

      } else {
        this.initialPickUpDay = this.weekdays[0];
      }
      this.setAsapDay(this.initialPickUpDay);
      this.setSelectedDay(this.initialPickUpDay);
    }
  },
};
</script>

<style lang="scss" scoped>
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 20px;
  font-weight: 900;
  color: #f2cd00;
  border-radius: 50%;
  height: 28px;
  width: 28px;
  padding: 4px;
  text-align: center;
}
</style>
