import { mapState, mapGetters, mapActions } from "vuex";
import { state } from "./modules/user";

export const authComputed = {
  ...mapState("auth", {
    account: (state) => state.account,
    guestAccount: (state) => state.guestAccount,
    recentOrders: (state) => state.recentOrders,
  }),
  ...mapGetters(
    "auth",
    {
      loggedIn: "loggedIn",
    },
    {
      isGuestUser: "isGuestUser",
    },
    "getRecentOrders"
  ),
};

export const authMethods = mapActions("auth", [
  "login",
  "register",
  "registerGuest",
  "setAccount",
  "setGuestAccount",
  "signOutUser",
  "forgotPassword",
  "checkRegistration",
  "removeGuestAccount",
]);

export const bagComputed = {
  ...mapState("bag", {
    basketId: (state) => state.basketId,
    basketIdToTransferBasketContent: (state) =>
      state.basketIdToTransferBasketContent,
    selectedMeals: (state) => state.selectedMeals,
    firstSelectedMealWithNoLocation: (state) =>
      state.firstSelectedMealWithNoLocation,
    selectedUpsellsBag: (state) => state.selectedUpsellsBag,
  }),
  ...mapGetters("bag", [
    "basketId",
    "basketIdToTransferBasketContent",
    "isBagOpen",
    "isBagEmpty",
    "bagSize",
    "totalPrice",
  ]),
};

export const bagMethods = mapActions("bag", [
  "addMeal",
  "updateMeal",
  "addFirstSelectedMealWithNoLocation",
  "setBasketId",
  "setBasketIdToTransferBasketContent",
  "setBagOpen",
  "removeMeal",
  "clearMeal",
  "resetSelectedMeals",
  "addUpsellToBag",
  "removeUpsellFromMeal",
  "removeUpsellFromBag",
  "decrementUpsellFromBag",
  "clearFirstMealWithNoLocation",
]);
export const siteComputed = {
  ...mapState("site", {
    menuPageOpen: (state) => state.menuPageOpen,
    storeMenu: (state) => state.storeMenu,
    storeCustMenu: (state) => state.storeCustMenu,
    storeStartTime: (state) => state.storeStartTime,
    storeEndTime: (state) => state.storeEndTime,
    currentTime: (state) => state.currentTime,
    orderStatusMode: (state) => state.orderStatusMode,
    searchedStores: (state) => state.searchedStores,
    allStores: (state) => state.allStores,
    faq: (state) => state.faq,
    locationPageOpen: (state) => state.locationPageOpen,
    chooseTimePageOpen: (state) => state.chooseTimePageOpen
  }),
  ...mapGetters("site", [
    "isMenuPageOpen",
    "getStoreMenu",
    "getStoreCustMenu",
    "getAllStores",
  ]),
};

export const siteMethods = mapActions("site", [
  "setMenuPageOpen",
  "setStoreMenu",
  "setStoreCustMenu",
  "setOrderStatusMode",
  "setSearchedStores",
  "setAllStores",
  "setFAQ",
  "setLocationPageOpen",
  "setTimePageOpen"
]);

export const BYOComputed = {
  ...mapState("byo", {
    modifiers: (state) => state.selectedModifiers,
    BYOtype: (state) => state.type,
    menuId: (state) => state.menuId,
    totalPrice: (state) => state.totalPrice,
    modifierCounter: (state) => state.modifierCounter,
    modifierRules: (state) => state.modifierRules,
    selectedModifiersCheck: (state) => state.selectedModifiersCheck,
    selectedModifiersGroup: (state) => state.selectedModifiersGroup,
    modifiersGroupForSelectedCYOItem: (state) =>
      state.modifiersGroupForSelectedCYOItem,
  }),
  ...mapGetters("byo", [
    "isModifierEmpty",
    "pickedModifiersString",
    "isModifierGroupFull",
    "getModifierGroupMax",
    "getModifierGroupCur",
    "isSelected",
    "getModifierGroupMin",
    "isModifierGroupMin",
    "getModifierRules",
  ]),
};

export const BYOMethods = mapActions("byo", [
  "addModifier",
  "removeModifier",
  "clearModifiers",
  "addEditModifier",
  "clearEditModifiers",
  "addModifierMinMax",
  "setType",
  "setMenuId",
  "setPrice",
  "addTest",
  "setNoModifier",
  "removeModifiersInGroup",
  "addModifiersGroupForSelectedCYOItem",
]);

export const userComputed = {
  ...mapState("user", {
    latitude: (state) => state.latitude,
    longitude: (state) => state.longitude,
    location: (state) => state.location,
    defaultLocation: (state) => state.defaultLocation,
    delivery: (state) => state.delivery,
    userCurrentLocation: () => state.userCurrentLocation,
    orderTypeGlobal: (state) => state.orderTypeGlobal,
    selectedDay: (state) => state.selectedDay,
    selectedTime: (state) => state.selectedTime,
    asapTime: (state) => state.asapTime,
    asapDay: (state) => state.asapDay,
    userCreditCards: (state) => state.userCreditCards,
    userSaveProduct: (state) => state.userSaveProduct,
    userDeliveryAddresses: (state) => state.userDeliveryAddresses,
    userBasicInformation: (state) => state.userBasicInformation,
    userRewardsMerchantBased: (state) => state.userRewardsMerchantBased,
    userCampaignRewardLoyaltyBased: (state) =>
      state.userCampaignRewardLoyaltyBased,
    userDefaultCreditCard: (state) => state.userDefaultCreditCard,
    userFavoriteLocations: (state) => state.userFavoriteLocations,
    userPreloadValue: (state) => state.userPreloadValue,
    userPaymentORCode: (state) => state.userPaymentORCode,
    cookies: (state) => state.cookies
  }),
  ...mapGetters("user", ["isLocationEmpty"]),
};

export const userMethods = mapActions("user", [
  "setLatitude",
  "setLongitude",
  "setLocation",
  "setDefaultLocation",
  "setOrderType",
  "setDelivery",
  "setSelectedDay",
  "removeSelectedDay",
  "setSelectedTime",
  "removeSelectedTime",
  "setAsapTime",
  "setAsapDay",
  "setUserCreditCards",
  "addUserCreditCards",
  "addUserSaveProduct",
  "setUserSaveProduct",
  "deleteUserSaveProduct",
  "addUserDeliveryAddresses",
  "setUserDeliveryAddresses",
  "deleteUserDeliveryAddress",
  "updateUserBasicInformation",
  "setUserBasicInformation",
  "setUserCampaignStatusDetails",
  "setUserRewardsMerchantBased",
  "pauseUserRewardsMerchantBased",
  "setUserCampaignRewardLoyaltyBased",
  "emptyUserCreditCards",
  "setUserFavoriteLocations",
  "setUserDefaultCreditCard",
  "deleteUserCreditCard",
  "setUserPreloadValue",
  "setUserPaymentORCode",
  "setCookies"
]);
