<script>
import Loading from "../../components/icon/loading.vue";
export default {
    components: {
    Loading,
  },

    page: {
        title: "Loading page...",
        meta: [{ name: "description", content: "Loading page..." }],
    },

};
</script>

<template>
    <div class="h-screen"><Loading/></div>
</template>