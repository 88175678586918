// In account object
// account.access_token.token : GrubHub access_token for levelup API calls
// account.authtoken : Olo authoken for all olo API calls

import axios from "axios";
import { errorService } from "../../notification/error.js";
import { userService } from "@/_services/user.js";
const baseUrl = `${process.env.VUE_APP_BASE_URL}/accounts`;
const baseUrlGrubHub = `${process.env.VUE_APP_GRUBHUB_BASE_URL}`;
const api_key = process.env.VUE_APP_GRUBHUB_API_KEY;

export const state = {
  account: getSavedState("auth.account") || {},
  guestAccount: getSavedState("auth.guestAccount") || {},
  recentOrders: getSavedState("user.recentOrders") || [],
};

export const getters = {
  loggedIn(state) {
    if (state.account !== null && Object.keys(state.account).length > 0) {
      return true;
    }
    return false;
  },
  isGuestUser(state) {
    if (state.guestAccount !== null && Object.keys(state.guestAccount).length > 0) {
      return true;
    }
    return false;
  },
  getRecentOrders(state) {
    return state.recentOrders;
  },
};

export const mutations = {
  SET_ACCOUNT(state, account) {
    state.account = account;
    saveState("auth.account", state.account);
  },
  SET_GUEST_ACCOUNT(state, guestAccount) {
    state.guestAccount = guestAccount;
    saveState("auth.guestAccount", state.guestAccount);
  },
  REMOVE_GUEST_ACCOUNT(state) {
    state.guestAccount = {};
    saveState("auth.guestAccount", state.guestAccount);
    removeItemFromLocalStorage("auth.guestAccount");
  },
  SET_RECENT_ORDER(state, recentOrders) {
    state.recentOrders = recentOrders;
    saveState("user.recentOrders", state.recentOrders);
  }
};

export const actions = {
  //Function to login user using the API from GrubHub
  async login({ commit }, { username, password} ) {
    try {
      let loginObject = {
        username: username,
        password: password,
        email: username,
      };
      const loginResponse = await userService.loginPx(loginObject);
      if (loginResponse.result == "authenticatedSuccess") {
        let cardNumber = loginResponse.primaryPrintedCardNumber;
        const requestOloGuestToken = await userService.requestOloGuestToken(
          cardNumber
        );
        let account = {};
        account.access_token = {};
        if (requestOloGuestToken.access_token) {
          try {
            account.access_token.token = requestOloGuestToken.access_token;
            const PaytronixOloUserRes = await axios.post(
              `${baseUrl}/users/getorcreate`,
              {
                provider: "paytronix",
                providertoken: requestOloGuestToken.access_token,
                provideruserid: username,
              }
            );
            account.authtoken =
              PaytronixOloUserRes.data.createGrubHubUserRes.authtoken;
                const userDetailsRes = await userService.requestUserDetails(
                  username
                );
                let user = {
                  cardNumber: cardNumber,
                  first_name: userDetailsRes.fields.firstName,
                  last_name: userDetailsRes.fields.lastName,
                  email: userDetailsRes.fields.email,
                  phone: userDetailsRes.fields.mobilePhone,
                };
              account = { ...account, user };
              commit("SET_ACCOUNT", account);
              return account;
          }
          catch (err){
            console.log(err);
          }
        }
      }else {
        throw loginResponse.result
      }
    }catch(err){
      console.log(err)
    }
      
          
  },

  //Function to create new user using the API from GrubHub
  // For Post Method we need to send data in request
  async register(
    { commit },
    registerObject
  ) {
    try {
      //we need to accept 404 and reject 200 to get the result
      let registerfield = {
        "style": "typed",
        "password": [
          registerObject.password
        ],
        "firstName":[registerObject.firstName],
            "lastName":[registerObject.lastName],
            "username": [
              registerObject.emailAddress
            ],
            "mobilePhone": [
              registerObject.contactNumber
            ],
            "email":[registerObject.emailAddress],
            "optIn": registerObject.emailOptIn,
            "textCampaignOptIn": registerObject.textOptIn
      }
      const registerResponse = await userService.registerPx(registerfield);
      if(registerResponse.result == 'cardCreatedSuccess'){
        let account = {};
        account.access_token = {};
      let cardNumber = registerResponse.printedCardNumber
      const requestOloGuestToken = await userService.requestOloGuestToken(
        cardNumber
      );
      account.access_token.token = requestOloGuestToken.access_token;
      if (requestOloGuestToken.access_token) {
        try {
          const PaytronixOloUserRes = await axios.post(
            `${baseUrl}/users/getorcreate`,
            {
              provider: "paytronix",
              providertoken: requestOloGuestToken.access_token,
              provideruserid: registerObject.emailAddress,
            }
          );
          account.authtoken =
          PaytronixOloUserRes.data.createGrubHubUserRes.authtoken;

          const userDetailsRes = await userService.requestUserDetails(
            registerObject.emailAddress
          );
          let user = {
            cardNumber: cardNumber,
            first_name: userDetailsRes.fields.firstName,
            last_name: userDetailsRes.fields.lastName,
            email: userDetailsRes.fields.email,
            phone: userDetailsRes.fields.mobilePhone,
          };

          account = {
            ...requestOloGuestToken,
            user,
            ...PaytronixOloUserRes.data.createGrubHubUserRes,
          };
          commit("SET_ACCOUNT", account);
        return account;
        } catch (error) {
          errorService.displayErrorAlertWithTarget(
            error.response.data[0].error.message
          );
          throw new Error(error.message);
        }
      }
      return account;
      }
      //hardcoded the error handling 
      else if(registerResponse.result == 'invalidInputs' ){
        if(registerResponse.errorsByField['setUserFields/email']){
         let newError =  registerResponse.errorsByField['setUserFields/email'][0].text;
         errorService.displayErrorAlertWithTarget(newError);
         throw new Error(newError);
        }
      } 
    } catch (error) {
      throw new Error(error);
    }
  },

  async registerFromOrder({ commit }, { password, optin, orderId }) {
    return axios
      .post(`${baseUrl}/orders/${orderId}/create`, { password, optin })
      .then((response) => {
        const account = response.data;
        commit("SET_ACCOUNT", {});
        return account;
      });
  },

  //Create a Guest User from GrubHub
  async registerGuest({ commit }, { first_name, last_name, email, phone }) {
    try {

      const guestAccount = {user:{
        'first_name':first_name,
        'guest_email':email,
        'last_name' : last_name,
        'phone':phone 
      }}
      // const registerGuestRes = await axios({
      //   method: "POST",
      //   url: `${baseUrlGrubHub}/v15/apps/users`,
      //   data: {
      //     api_key,
      //     user: {
      //       email,
      //       first_name,
      //       last_name,
      //       phone,
      //       guest: true,
      //     },
      //     permission_keynames: [
      //       "create_orders",
      //       "edit_user_basic_info",
      //       "read_user_orders",
      //       "read_user_basic_info",
      //       "manage_user_campaigns",
      //       "manage_user_addresses",
      //       "manage_user_payment_methods",
      //       "read_qr_code",
      //     ],
      //   },
      //   headers: {
      //     Accept: "application/json",
      //     "Content-Type": "application/json",
      //   },
      // });

      // const guestAccount = registerGuestRes.data;

      commit("SET_GUEST_ACCOUNT", guestAccount);

      return guestAccount;
    } catch (error) {
      errorService.displayErrorAlertWithTarget(
        // error.response.data[0].error.message
      );
      throw new Error(error.message);
    }
  },

  async forgotPassword({ commit }, { email }) {
    try {
      const forgotPasswordResponse = await axios({
        method: "POST",
        url: `${baseUrlGrubHub}/v15/passwords`,
        data: {
          email,
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      commit("SET_ACCOUNT", {});
      return forgotPasswordResponse;
    } catch (forgotPasswordError) {
      throw new Error(forgotPasswordError.response.data[0].error.message);
    }
  },

  async checkRegistration({ commit }, { email }) {
    try {
      const checkRegistrationResponse = await axios({
        method: "GET",
        url: `${baseUrlGrubHub}/v15/registration?api_key=${api_key}&email=${email}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      commit("SET_ACCOUNT", {});
      return checkRegistrationResponse;
    } catch (error) {
      throw new Error(error.response.data[0].error.message);
    }
  },

  signOutUser({ commit }, { authToken }) {
    axios.delete(`${baseUrl}/users/${authToken}`);
    commit('user/SET_LATITUDE', "", { root: true });
    commit('user/SET_LONGITUDE', "", { root: true });
    commit('user/SET_LOCATION', "", { root: true });
    commit('user/SET_DELIVERY', "", { root: true });
    commit('user/SET_ORDER_TYPE', "", { root: true });
    commit('user/SET_SELECTED_DAY', "", { root: true });
    commit('user/SET_SELECTED_TIME', "", { root: true });
    commit('user/SET_ASAP_TIME', "", { root: true });
    commit('user/SET_ASAP_DAY', "", { root: true });
    commit('user/SET_USER_CREDIT_CARDS', [], { root: true });
    commit('user/SET_USER_DEFAULT_CREDIT_CARD', [], { root: true });
    commit('user/SET_USER_DELIVERY_ADDRESSES', [], { root: true });
    commit('user/SET_USER_BASIC_INFORMATION', {}, { root: true });
    commit('user/SET_USER_REWARDS_MERCHANT_BASED', [], { root: true });
    commit('user/SET_USER_CAMPAIGN_REWARD_LOYALTY_BASED', {}, { root: true });
    commit('user/SET_USER_FAVORITE_LOCATIONS', [], { root: true });
    commit('user/SET_USER_CAMPAIGN_STATUS_DETAILS', {}, { root: true });
    commit('bag/CLEAR_MEAL', null, { root: true });
    commit("SET_RECENT_ORDER", []);
    commit("SET_ACCOUNT", {});
    commit("SET_GUEST_ACCOUNT", {});
    commit('user/SET_USER_PAYMENT_QRCODE', {}, { root: true });
    deleteUserAuthDetails();
  },

  setAccount({ commit }, account) {
    commit("SET_ACCOUNT", account);
  },
  setGuestAccount({ commit }, guestAccount) {
    commit("SET_GUEST_ACCOUNT", guestAccount);
  },
  removeGuestAccount({ commit }) {
    commit("REMOVE_GUEST_ACCOUNT");
  },
};

// Private helpers

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key));
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state));
}

function removeItemFromLocalStorage(key) {
  window.localStorage.removeItem(key);
}

function deleteUserAuthDetails() {
  window.localStorage.removeItem("user.latitude");
  window.localStorage.removeItem("user.longitude");
  window.localStorage.removeItem("user.location");
  window.localStorage.removeItem("user.delivery");
  window.localStorage.removeItem("user.orderTypeGlobal");
  window.localStorage.removeItem("user.selectedDay");
  window.localStorage.removeItem("user.selectedTime");
  window.localStorage.removeItem("user.asapTime");
  window.localStorage.removeItem("user.asapDay");
  window.localStorage.removeItem("user.userCreditCards");
  window.localStorage.removeItem("user.userDefaultCreditCard");
  window.localStorage.removeItem("user.userSaveProduct");
  window.localStorage.removeItem("user.userDeliveryAddresses");
  window.localStorage.removeItem("user.userBasicInformation");
  window.localStorage.removeItem("user.userRewardsMerchantBased");
  window.localStorage.removeItem("user.userCampaignRewardLoyaltyBased");
  window.localStorage.removeItem("user.userFavoriteLocations");
  window.localStorage.removeItem("user.faveLocations");
  window.localStorage.removeItem("user.userCampaignStatusDetails");
  window.localStorage.removeItem("user.recentOrders");
  window.localStorage.removeItem("bag.selectedMeals");
  window.localStorage.removeItem("auth.guestAccount");
}
