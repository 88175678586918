import axios from "axios";
import { errorService } from "../../notification/error.js";
import { userService } from "@/_services/user.js";
const baseUrl = `${process.env.VUE_APP_BASE_URL}/accounts`;
const baseUrlGrubHub = `${process.env.VUE_APP_GRUBHUB_BASE_URL}`;
const grubHubMerchantId = `${process.env.VUE_APP_GRUBHUB_MERCHANT_ID}`;
const grubHubCampaignId = `${process.env.VUE_APP_GRUBHUB_CAMPAIGN_ID}`;
const grubHubSpendBasedLoyaltyCampaignId = `${process.env.VUE_APP_GRUBHUB_SPEND_BASED_LOYALTY_CAMPAIGN_ID}`;
const defaultLocationId = process.env.VUE_APP_DEFAULT_LOCATION_ID;

export const state = {
  latitude: getSavedState("user.latitude") || "",
  longitude: getSavedState("user.longitude") || "",
  location: getSavedState("user.location") || {},
  defaultLocation: getSavedState("user.defaultLocation") || {
    id: defaultLocationId,
  },
  delivery: getSavedState("user.delivery") || {},
  orderTypeGlobal: getSavedState("user.orderTypeGlobal") || "",
  selectedDay: getSavedState("user.selectedDay") || "",
  selectedTime: getSavedState("user.selectedTime") || "",
  asapTime: getSavedState("user.asapTime") || "",
  asapDay: getSavedState("user.asapDay") || "",
  userCurrentLocation: getSavedState("user.userCurrentLocation"),
  //olo calls for user
  userFavoriteLocations: getSavedState("user.userFavoriteLocations") || [],
  userSaveProduct: getSavedState("user.userSaveProduct") || [],
  //grub hub calls
  userCreditCards: getSavedState("user.userCreditCards") || [],
  userPreloadValue: getSavedState("user.userPreloadValue"),
  userPaymentORCode: getSavedState("user.userPaymentORCode") || {},
  userDeliveryAddresses: getSavedState("user.userDeliveryAddresses") || [],
  userBasicInformation: getSavedState("user.userBasicInformation") || {},
  userCampaignStatusDetails:
    getSavedState("user.userCampaignStatusDetails") || {},
  userRewardsMerchantBased:
    getSavedState("user.userRewardsMerchantBased") || [],
  userCampaignRewardLoyaltyBased:
    getSavedState("user.userCampaignRewardLoyaltyBased") || {},
  userDefaultCreditCard: getSavedState("user.userDefaultCreditCard") || {},
  cookies: getSavedState("user.cookies") || {},
};

export const getters = {
  isLocationEmpty(state) {
    return state.location === null;
  },
};

export const mutations = {
  SET_COOKIES() {
    let pairs = document.cookie.split(";");
    let cookies = {};
    for (let i = 0; i < pairs.length; i++) {
      let pair = pairs[i].split("=");
      cookies[(pair[0] + "").trim()] = unescape(pair.slice(1).join("="));
    }
    saveState("user.cookies", cookies);
  },
  SET_LATITUDE(state, newLatitude) {
    state.latitude = newLatitude;
    saveState("user.latitude", state.latitude);
  },
  SET_LONGITUDE(state, newLongitude) {
    state.longitude = newLongitude;
    saveState("user.longitude", state.longitude);
  },
  SET_USER_CURRENT_LOCATION(state, newUserCurrentLocation) {
    state.userCurrentLocation = newUserCurrentLocation;
    saveState("user.userCurrentLocation", state.userCurrentLocation);
  },
  SET_LOCATION(state, newLocation) {
    state.location = newLocation;
    saveState("user.location", state.location);
  },
  SET_DEFAULT_LOCATION(state, newDefaultLocation) {
    state.defaultLocation = newDefaultLocation;
    saveState("user.defaultLocation", state.defaultLocation);
  },
  SET_DELIVERY(state, newDeliveryAddress) {
    state.delivery = newDeliveryAddress;
    saveState("user.delivery", state.delivery);
  },
  SET_ORDER_TYPE(state, newOrderType) {
    state.orderTypeGlobal = newOrderType;
    saveState("user.orderTypeGlobal", state.orderTypeGlobal);
  },
  SET_SELECTED_DAY(state, newSelectedDay) {
    state.selectedDay = newSelectedDay;
    saveState("user.selectedDay", state.selectedDay);
  },
  REMOVE_SELECTED_DAY(state) {
    state.selectedDay = null;
    saveState("user.selectedDay", state.selectedDay);
    removeItemFromLocalStorage("user.selectedDay");
  },
  SET_SELECTED_TIME(state, newSelectedTime) {
    state.selectedTime = newSelectedTime;
    saveState("user.selectedTime", state.selectedTime);
  },
  REMOVE_SELECTED_TIME(state) {
    state.selectedTime = null;
    saveState("user.selectedTime", state.selectedTime);
    removeItemFromLocalStorage("user.selectedTime");
  },
  SET_ASAP_TIME(state, newAsapTime) {
    state.asapTime = newAsapTime;
    saveState("user.asapTime", state.asapTime);
  },
  SET_ASAP_DAY(state, newAsapDay) {
    state.asapDay = newAsapDay;
    saveState("user.asapDay", state.asapDay);
  },
  SET_USER_FAVORITE_LOCATIONS(state, userFavoriteLocations) {
    state.userFavoriteLocations = userFavoriteLocations;
    saveState("user.userFavoriteLocations", state.userFavoriteLocations);
  },
  SET_USER_SAVE_PRODUCT(state, userSaveProduct) {
    state.userSaveProduct = userSaveProduct;
    saveState("user.userSaveProduct", state.userSaveProduct);
  },
  ADD_USER_SAVE_PRODUCT(state, userSaveProduct) {
    state.userSaveProduct.push(userSaveProduct);
    saveState("user.userSaveProduct", state.userSaveProduct);
  },
  DELETE_USER_SAVE_PRODUCT(state, id) {
    state.userSaveProduct = state.userSaveProduct.filter(
      (obj) => obj.id !== id
    );
    saveState("user.userSaveProduct", state.userSaveProduct);
  },
  SET_USER_PAYMENT_QRCODE(state, qrCode) {
    state.userPaymentORCode = qrCode;
    saveState("user.userPaymentORCode", state.userPaymentORCode);
  },
  SET_USER_PRELOAD_VALUE(state, preloadValue) {
    state.userPreloadValue = preloadValue;
    saveState("user.userPreloadValue", state.userPreloadValue);
  },
  SET_USER_CREDIT_CARDS(state, userCreditCards) {
    state.userCreditCards = userCreditCards;
    saveState("user.userCreditCards", state.userCreditCards);
  },
  ADD_USER_CREDIT_CARDS(state, userCreditCards) {
    state.userCreditCards.push(userCreditCards);
    saveState("user.userCreditCards", state.userCreditCards);
  },
  SET_USER_DEFAULT_CREDIT_CARD(state, userDefaultCreditCard) {
    state.userDefaultCreditCard = userDefaultCreditCard;
    saveState("user.userDefaultCreditCard", state.userDefaultCreditCard);
  },
  SET_USER_DELIVERY_ADDRESSES(state, userDeliveryAddresses) {
    state.userDeliveryAddresses = userDeliveryAddresses;
    saveState("user.userDeliveryAddresses", state.userDeliveryAddresses);
  },
  ADD_USER_DELIVERY_ADDRESSES(state, userDeliveryAddresses) {
    state.userDeliveryAddresses.push(userDeliveryAddresses);
    saveState("user.userDeliveryAddresses", state.userDeliveryAddresses);
  },
  DELETE_USER_DELIVERY_ADDRESSES(state, id) {
    state.userDeliveryAddresses = state.userDeliveryAddresses.filter(
      (address) => address.id !== id
    );
    saveState("user.userDeliveryAddresses", state.userDeliveryAddresses);
  },
  DELETE_USER_CREDIT_CARD(state, id) {
    state.userCreditCards = state.userCreditCards.filter(
      (card) => card.id !== id
    );
    saveState("user.userCreditCards", state.userCreditCards);
  },
  SET_USER_BASIC_INFORMATION(state, userBasicInformation) {
    state.userBasicInformation = userBasicInformation;
    saveState("user.userBasicInformation", state.userBasicInformation);
  },
  SET_USER_CAMPAIGN_STATUS_DETAILS(state, userCampaignStatusDetails) {
    state.userCampaignStatusDetails = userCampaignStatusDetails;
    saveState(
      "user.userCampaignStatusDetails",
      state.userCampaignStatusDetails
    );
  },
  SET_USER_REWARDS_MERCHANT_BASED(state, userRewardsMerchantBased) {
    state.userRewardsMerchantBased = userRewardsMerchantBased;
    saveState("user.userRewardsMerchantBased", state.userRewardsMerchantBased);
  },
  PAUSE_USER_REWARDS_MERCHANT_BASED(state, id, isPaused) {
    const foundReward =
      state.userRewardsMerchantBased[find(id, state.userRewardsMerchantBased)];
    state.userRewardsMerchantBased[
      foundReward.reward && foundReward.reward.pause
    ] = isPaused;
    saveState("user.userRewardsMerchantBased", state.userRewardsMerchantBased);
  },
  SET_USER_CAMPAIGN_REWARD_LOYALTY_BASED(
    state,
    userCampaignRewardLoyaltyBased
  ) {
    state.userCampaignRewardLoyaltyBased = userCampaignRewardLoyaltyBased;
    saveState(
      "user.userCampaignRewardLoyaltyBased",
      state.userCampaignRewardLoyaltyBased
    );
  },
};

export const actions = {
  setCookies({ commit }, cookies) {
    commit("SET_COOKIES", cookies);
  },
  setUserCurrentLocation({ commit }, newUserCurrentLocation) {
    commit("SET_USER_CURRENT_LOCATION", newUserCurrentLocation);
  },
  setLatitude({ commit }, newLatitude) {
    commit("SET_LATITUDE", newLatitude);
  },
  setLongitude({ commit }, newLongitude) {
    commit("SET_LONGITUDE", newLongitude);
  },
  setLocation({ commit }, newLocation) {
    commit("SET_LOCATION", newLocation);
  },
  setDefaultLocation({ commit }, newDefaultLocation) {
    commit("SET_DEFAULT_LOCATION", newDefaultLocation);
  },
  setDelivery({ commit }, newDeliveryAddress) {
    commit("SET_DELIVERY", newDeliveryAddress);
  },
  setOrderType({ commit }, newOrderType) {
    commit("SET_ORDER_TYPE", newOrderType);
  },
  setSelectedDay({ commit }, newSelectedDay) {
    commit("SET_SELECTED_DAY", newSelectedDay);
  },
  removeSelectedDay({ commit }) {
    commit("REMOVE_SELECTED_DAY");
  },
  setSelectedTime({ commit }, newSelectedTime) {
    commit("SET_SELECTED_TIME", newSelectedTime);
  },
  removeSelectedTime({ commit }) {
    commit("REMOVE_SELECTED_TIME");
  },
  setAsapTime({ commit }, newAsapTime) {
    commit("SET_ASAP_TIME", newAsapTime);
  },
  setAsapDay({ commit }, newAsapDay) {
    commit("SET_ASAP_DAY", newAsapDay);
  },
  emptyUserCreditCards({ commit }, value) {
    commit("SET_USER_CREDIT_CARDS", [], value);
  },
  async setUserFavoriteLocations({ commit }, { auth_token }) {
    try {
      const userFavoriteLocations = await axios.get(
        `${baseUrl}/users/${auth_token}/favelocations`
      );
      commit(
        "SET_USER_FAVORITE_LOCATIONS",
        userFavoriteLocations.data &&
          userFavoriteLocations.data.getUserFaveLocationsRes.favelocations.slice(
            0,
            5
          )
      );
      return (
        userFavoriteLocations.data &&
        userFavoriteLocations.data.getUserFaveLocationsRes.favelocations
      );
    } catch (error) {
      console.log(error);
    }
  },
  async addUserSaveProduct({ commit }, { authToken, params }) {
    try {
      const resp = await axios.post(
        `${baseUrl}/users/${authToken}/savedproducts`,
        params
      );
      if (resp.data.createSaveProductRes)
        commit("ADD_USER_SAVE_PRODUCT", resp.data.createSaveProductRes);
      return resp.data.createSaveProductRes;
    } catch (error) {
      errorService.displayErrorAlertWithTarget(error.response.data.message);
      throw new Error(error.response.data.message);
    }
  },
  async setUserSaveProduct({ commit }, { authToken }) {
    try {
      let resp = await axios.get(`${baseUrl}/users/${authToken}/savedproducts`);

      commit(
        "SET_USER_SAVE_PRODUCT",
        resp.data.getSaveProductRes.savedproducts.slice(0, 5)
      );
      return resp.data.getSaveProductRes.savedproducts;
    } catch (error) {
      throw error.response.data.message;
    }
  },
  async deleteUserSaveProduct({ commit }, { authToken, id }) {
    try {
      const deleted = await axios.delete(
        `${baseUrl}/users/${authToken}/savedproducts/${id}`
      );
      commit("DELETE_USER_SAVE_PRODUCT", id);
      return deleted;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async setUserPaymentORCode({ commit }, { grubHub_access_token }) {
    // console.log(grubHub_access_token)
    try {
      const userPaymentORCode = await axios({
        method: "GET",
        url: `${baseUrlGrubHub}/v15/qr_codes`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token user="${grubHub_access_token}"`,
        },
      });
      commit("SET_USER_PAYMENT_QRCODE", userPaymentORCode);
      return userPaymentORCode;
    } catch (error) {
      console.log(error.response);
    }
  },
  async setUserPreloadValue(
    { commit },
    { grubHub_access_token, preloadAmount }
  ) {
    try {
      const userPreloadValue = await axios({
        method: "PUT",
        url: `${baseUrlGrubHub}/v15/apps/payment_preference`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token user="${grubHub_access_token}"`,
        },
        data: {
          payment_preference: {
            force_two_touch_in_store: false,
            preload: true,
            preload_reload_threshold_amount: 2500,
            preload_value_amount: preloadAmount,
          },
        },
      });
      commit("SET_USER_PRELOAD_VALUE", userPreloadValue.data);
      return userPreloadValue.data;
    } catch (error) {
      throw error.response.data[0].error.message;
    }
  },
  async setUserCreditCards({ commit }, grubHub_access_token) {
    try {
      const userCreditCards = await axios({
        method: "GET",
        url: `${baseUrlGrubHub}/v15/credit_cards`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token user="${grubHub_access_token}"`,
        },
      });
      commit("SET_USER_CREDIT_CARDS", userCreditCards.data);
      return userCreditCards.data;
    } catch (error) {
      console.log(error.response);
    }
  },
  setUserDefaultCreditCard({ commit }, newUserDefaultCreditCard) {
    commit("SET_USER_DEFAULT_CREDIT_CARD", newUserDefaultCreditCard);
  },
  async addUserCreditCards({ commit }, { grubHub_access_token, nonce_token }) {
    try {
      const userCreditCards = await axios({
        method: "POST",
        url: `${baseUrlGrubHub}/v15/credit_cards`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token user="${grubHub_access_token}"`,
        },
        data: {
          credit_card: {
            braintree_payment_nonce: nonce_token,
            nickname: "Mahak",
            one_order_only: false,
          },
        },
      });
      commit("ADD_USER_CREDIT_CARDS", userCreditCards.data);
      return userCreditCards;
    } catch (error) {
      console.log(error);
    }
  },
  async setUserCampaignStatusDetails({ commit }, { grubHub_access_token }) {
    try {
      const userCampaignStatusDetails = await axios({
        method: "GET",
        url: `${baseUrlGrubHub}/v15/campaigns/${grubHubCampaignId}/raw_spend_based_status_v1`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token user="${grubHub_access_token}"`,
        },
      });
      commit(
        "SET_USER_CAMPAIGN_STATUS_DETAILS",
        userCampaignStatusDetails.data
      );
      return userCampaignStatusDetails.data;
    } catch (error) {
      console.log(error);
    }
  },
  async setUserRewardsMerchantBased({ commit }, { grubHub_access_token }) {
    try {
      const userRewardsMerchantBased = await axios({
        method: "GET",
        url: `${baseUrlGrubHub}/v15/merchants/${grubHubMerchantId}/rewards?lat=42.373342&lng=-71.116508`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token user="${grubHub_access_token}"`,
        },
      });
      commit("SET_USER_REWARDS_MERCHANT_BASED", userRewardsMerchantBased.data);
      return userRewardsMerchantBased.data;
    } catch (error) {
      console.log(error);
    }
  },
  async pauseUserRewardsMerchantBased(
    { commit },
    { grubHub_access_token, claim_id, isPaused }
  ) {
    try {
      const userRewardsMerchantBased = await axios({
        method: "PUT",
        url: `${baseUrlGrubHub}/v15/merchants/${grubHubMerchantId}/rewards/${claim_id}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token user="${grubHub_access_token}"`,
        },
        data: {
          reward: {
            paused: isPaused,
          },
        },
      });
      commit("PAUSE_USER_REWARDS_MERCHANT_BASED", claim_id, isPaused);
      return userRewardsMerchantBased.data;
    } catch (error) {
      throw error.response.data[0];
    }
  },
  async setUserCampaignRewardLoyaltyBased(
    { commit },
    { grubHub_access_token }
  ) {
    try {
      const userCampaignRewardLoyaltyBased = await axios({
        method: "GET",
        url: `${baseUrlGrubHub}/v15/campaigns/${grubHubSpendBasedLoyaltyCampaignId}/spend_based_loyalty_v1`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token user="${grubHub_access_token}"`,
        },
      });
      commit(
        "SET_USER_CAMPAIGN_REWARD_LOYALTY_BASED",
        userCampaignRewardLoyaltyBased.data
      );
      return userCampaignRewardLoyaltyBased.data;
    } catch (error) {
      console.log(error);
    }
  },
  async setUserBasicInformation({ commit }, { username }) {
    try {
      const userDetailsRes = await userService.requestUserDetails(
        username
      );
      let account = this.account;
      let user = {
        first_name: userDetailsRes.fields.firstName,
        last_name: userDetailsRes.fields.lastName,
        email: userDetailsRes.fields.email,
        phone: userDetailsRes.fields.mobilePhone,
      };
      account.user = { ...account.user, ...user };
      commit("SET_USER_BASIC_INFORMATION", account);
      return user;
    } catch (error) {
      console.log(error);
    }
  },
  /* eslint-disable */
  async updateUserBasicInformation(
    { commit }, { username,printedCardNumber, setUserFields }
  ) {
    try {
      const updateUserInfo = await userService.updateUserInfoPx({cardNumber:printedCardNumber,setUserFields:setUserFields});
        if(updateUserInfo.result == 'success'){
          const userBasicInformation =  await userService.requestUserDetails(
            username
          );
          let account = this.account;
          let user = {
            first_name: userDetailsRes.fields.firstName,
            last_name: userDetailsRes.fields.lastName,
            email: userDetailsRes.fields.email,
            phone: userDetailsRes.fields.mobilePhone,
          };
          account.user = { ...account.user, ...user };
          commit("SET_USER_BASIC_INFORMATION", account);
          return userBasicInformation;
        }
        else{
          throw updateUserInfo;
        }
      
    } catch (error) {
      throw error
    }
  },
  async setUserDeliveryAddresses({ commit }, { username }) {
    try {
      const userDetailRes = await userService.requestUserDetails(
        username
      );
      if(userDetailRes.result == "success"){
        commit("SET_USER_DELIVERY_ADDRESSES", userDetailRes.addresses);
      }

      return userDetailRes.addresses;
    } catch (error) {
      console.log(error);
    }
  },
  async addUserDeliveryAddresses(
    { commit },
    {
      grubHub_access_token,
      address_type,
      street_address,
      extended_address,
      locality,
      region,
      postal_code,
      delivery_instructions,
    }
  ) {
    try {
      const userDeliveryAddresses = await axios({
        method: "POST",
        url: `${baseUrlGrubHub}/v15/user_addresses`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token user="${grubHub_access_token}"`,
        },
        data: {
          user_address: {
            address_type,
            street_address,
            extended_address,
            locality,
            region,
            postal_code,
            delivery_instructions,
          },
        },
      });
      commit("ADD_USER_DELIVERY_ADDRESSES", userDeliveryAddresses.data);
      return userDeliveryAddresses.data;
    } catch (error) {
      console.log(error);
    }
  },
  async deleteUserDeliveryAddress({ commit }, { grubHub_access_token, id }) {
    try {
      await axios({
        method: "DELETE",
        url: `${baseUrlGrubHub}/v15/user_addresses/${id}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token user="${grubHub_access_token}"`,
        },
      });
      commit("DELETE_USER_DELIVERY_ADDRESSES", id);
    } catch (error) {
      console.log(error);
    }
  },

  async deleteUserCreditCard({ commit }, { grubHub_access_token, id }) {
    try {
      const cardDeleted = await axios({
        method: "DELETE",
        url: `${baseUrlGrubHub}/v15/credit_cards/${id}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token user="${grubHub_access_token}"`,
        },
      });

      commit("DELETE_USER_CREDIT_CARD", id);
      return cardDeleted.data;
    } catch (error) {
      console.log(error);
    }
  },
};

// Private helpers

function getSavedState(key) {
  if (window.localStorage.getItem(key) !== "undefined") {
    return JSON.parse(window.localStorage.getItem(key));
  } else {
    return null;
  }
}

function saveState(key, state) {
  if (state !== "undefined" && key !== "undefined") {
    window.localStorage.setItem(key, JSON.stringify(state));
  } else {
    return null;
  }
}

function removeItemFromLocalStorage(key) {
  window.localStorage.removeItem(key);
}

function find(id, state) {
  for (let i = 0; i < state.length; i++) {
    if (state[i].reward.id === id) {
      return i;
    }
  }
  return -1;
}
