import axios from "axios";

const baseUrl = `${process.env.VUE_APP_BASE_URL}`;
const baseUrlGrubHub = `${process.env.VUE_APP_GRUBHUB_BASE_URL}`;
const api_key = process.env.VUE_APP_GRUBHUB_API_KEY;
const app_id = process.env.VUE_APP_GRUBHUB_APP_ID;

export const state = {
  menuPageOpen: getSavedState("site.menuPageOpen") || false,
  storeMenu: getSavedState("site.storeMenu"),
  storeCustMenu: getSavedState("site.storeCustMenu"),
  orderStatusMode: getSavedState("site.orderStatusMode") || 0,
  searchedStores: getSavedState("site.searchedStores") || [],
  allStores: getSavedState("site.allStores") || [],
  faq: getSavedState("site.faq"),
  locationPageOpen: getSavedState("site.locationPageOpen") || false,
  chooseTimePageOpen: getSavedState("site.chooseTimePageOpen") || false,
};

export const mutations = {
  SET_MENUPAGE_OPEN(state, isOpen) {
    state.menuPageOpen = isOpen;
    saveState("site.menuPageOpen", state.menuPageOpen);
  },
  SET_LOCATIONPAGE_OPEN(state, isOpen) {
    state.locationPageOpen = isOpen;
    saveState("site.locationPageOpen", state.locationPageOpen);
  },
  SET_TIMEPAGE_OPEN(state, isOpen) {
    state.chooseTimePageOpen = isOpen;
    saveState("site.chooseTimePageOpen", state.chooseTimePageOpen);
  },
  SET_STORE_MENU(state, storeMenu) {
    state.storeMenu = storeMenu;
    saveState("site.storeMenu", state.storeMenu);
  },
  SET_STORE_CUST_MENU(state, storeCustMenu) {
    state.storeCustMenu = storeCustMenu;
    saveState("site.storeCustMenu", state.storeCustMenu);
  },
  SET_ORDER_STATUS_MODE(state, orderStatusMode) {
    state.orderStatusMode = orderStatusMode;
    saveState("site.orderStatusMode", state.orderStatusMode);
  },
  SET_SEARCHED_STORES(state, stores) {
    state.searchedStores = stores;
    saveState("site.searchedStores", state.searchedStores);
  },
  SET_ALL_STORES(state, stores) {
    state.allStores = stores;
    saveState("site.allStores", state.allStores);
  },
  SET_FAQ(state, faqs) {
    state.faq = faqs;
    saveState("site.faq", state.faq);
  },
};

export const getters = {
  isMenuPageOpen(state) {
    return state.menuPageOpen;
  },
  getStoreMenu(state) {
    return state.storeMenu;
  },
  getStoreCustMenu(state) {
    return state.storeCustMenu;
  },
  getAllStores(state) {
    return state.allStores;
  },
};

export const actions = {
  setMenuPageOpen({ commit }, isOpen) {
    commit("SET_MENUPAGE_OPEN", isOpen);
  },
  setLocationPageOpen({ commit }, isOpen) {
    commit("SET_LOCATIONPAGE_OPEN", isOpen);
  },
  setTimePageOpen({ commit }, isOpen) {
    commit("SET_TIMEPAGE_OPEN", isOpen);
  },
  async setStoreMenu({ commit }, { vendorId }) {
    try {
      const menus = await axios.get(`${baseUrl}/menu/getMenu/${vendorId}`);
      if (menus) {
        commit("SET_STORE_MENU", menus.data);
        return menus.data;
      }
    } catch (e) {
      console.log(e.response);
    }
  },
  async setStoreCustMenu({ commit }, { vendorId }) {
    try {
      const menus = await axios.get(
        `${baseUrl}/menu/getCustomizeMenu/${vendorId}`
      );
      if (menus) {
        commit("SET_STORE_CUST_MENU", menus.data);
        return menus.data;
      }
    } catch (e) {
      console.log(e.response);
    }
  },
  setOrderStatusMode({ commit }, newOrderStatusMode) {
    commit("SET_ORDER_STATUS_MODE", newOrderStatusMode);
    return newOrderStatusMode;
  },
  setSearchedStores({ commit }, newStores) {
    commit("SET_SEARCHED_STORES", newStores);
  },
  setAllStores({ commit }, allStores) {
    commit("SET_ALL_STORES", allStores);
  },
  async setFAQ(
    { commit }
  ) {
    try {
      const siteFAQ = await axios({
        method: "GET",
        url: `${baseUrlGrubHub}/v15/white_label_apps/${app_id}?api_key=${api_key}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      commit(
        "SET_FAQ",
        siteFAQ.data
      );
      return siteFAQ.data;
    } catch (error) {
      console.error(error);
    }
  },
};

// Private helpers

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key));
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state));
}
