<template>
  <Layout bgColor="white">
    <div
      class="flex w-full relative z-10 top-0 font-roboto overflow-content"
      ref="orderLocations"
    >
      <div
        class="lg:w-2/3 md:flex hidden h-screen overflow-content"
        style="background-size: 100% 100%; background-repeat: no-repeat;"
        :style="styleObject"
      ></div>
      <div
        class="ml-auto bg-white overflow-y-auto text-sm font-normal max-h-screen lg:w-1/3 md:w-full w-full z-10 h-screen pt-0 overflow-content"
      >
        <OrderType />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../layout/main.vue";
import OrderType from "../content/orderType.vue";
import background from "@/assets/images/order.webp";
import { userComputed, authComputed } from "@/state/helpers";
import { siteComputed } from "../../state/helpers";

export default {
  components: {
    Layout,
    OrderType,
},
  computed: {
    ...userComputed,
    ...siteComputed,
    ...authComputed
  },
  data() {
    return {
      isModalPickupShowing: false,
      styleObject: {
        backgroundImage: "url(" + background + ")",
      },
    };
  },
  methods: {
    showModalPickup() {
      this.isModalPickupShowing = !this.isModalPickupShowing;
    },
  },
};
</script>
