// import axios from "axios";
import api from "../api";

const baseUrl = `${process.env.VUE_APP_BASE_URL}/store`;

export const storeService = {
  getStores,
  getStoreHours,
  checkDelivery,
  getNearbyStores,
  getStoreHoursOnChosenDay,
  getStoreById
};

async function getStores() {
  return api
    .get(`${baseUrl}/getStore`, {})
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

async function getStoreById(storeId) {
    return api
      .get(`${baseUrl}/${storeId}`, {})
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error.response.data;
      });
  }

//returns the current Day Object for the given store
async function getStoreHours(storeId) {
  try {
    const res = await api.get(`${baseUrl}/${storeId}/getStoreHours`, {});
    return res.data;
  } catch (error) {
    throw error.response.data;
  }
}


//returns the current Day Object for the given store
async function getStoreHoursOnChosenDay(storeId, chosenDay) {
    try {
      const res = await api.get(`${baseUrl}/${storeId}/getStoreHours/${chosenDay}`);
      return res && res.data;
    } catch (error) {
      console.log(error)
    }
  }

async function checkDelivery(storeId, body) {
    console.log('storeId',storeId)
  return await api
    .post(`${baseUrl}/${storeId}/checkDelivery`, body)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

//check nearby stores for delivery
function getNearbyStores(lat, long) {
  return api
    .get(`${baseUrl}/near?lat=${lat}&long=${long}&radius=50000&limit=10`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}
