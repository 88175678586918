<template>
  <svg
    class="fill-current cursor-pointer"
    :class="color + ' ' + size + ' '"
    viewBox="0 0 33 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.9073 22.5629H11.7334V12.0957C12.4722 12.8257 13.1617 13.5008 13.8512 14.1845C14.306 13.7152 14.7435 13.2603 15.1954 12.7939C14.532 12.1218 13.8483 11.4293 13.1356 10.7051H31.5321V10.934C31.5321 13.8803 31.535 16.8295 31.5321 19.7759C31.5321 21.0419 30.663 22.1312 29.4288 22.4441C29.2579 22.4875 29.084 22.5223 28.9102 22.5629H28.9073Z"
      stroke="#232323"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 10.7048C1.07822 10.6961 1.15644 10.6816 1.23466 10.6816C3.52916 10.6816 5.82655 10.6816 8.12104 10.6816C8.19057 10.6816 8.2572 10.6903 8.3586 10.6961C7.64012 11.4059 6.93903 12.0983 6.25531 12.7733C6.75941 13.2803 7.20266 13.7294 7.67199 14.2045C8.34701 13.5295 9.03941 12.8371 9.76369 12.1128C9.77527 12.2432 9.78686 12.3272 9.78686 12.4112C9.78686 15.6965 9.78686 18.9847 9.78686 22.27C9.78686 22.3685 9.77238 22.467 9.76658 22.5655H3.62476C3.55813 22.5452 3.49439 22.5191 3.42776 22.5104C2.3964 22.3511 1.66343 21.7949 1.23177 20.8504C1.13327 20.6389 1.07822 20.4072 1 20.1841C1 17.0263 1 13.8684 1 10.7106V10.7048Z"
      stroke="#232323"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 3.37506C1.08402 3.1346 1.14196 2.87965 1.25205 2.65368C1.76483 1.60494 2.62237 1.03711 3.7812 1.01683C5.73674 0.984962 7.69227 1.00814 9.6507 1.00814C9.67967 1.00814 9.70864 1.01972 9.76079 1.03131V4.00083C8.14132 3.27945 6.62035 3.34608 5.40067 4.73379C4.35772 5.92159 4.378 7.29192 5.04723 8.73467H1C1 6.94717 1 5.15966 1 3.37216V3.37506Z"
      stroke="#232323"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M31.5207 8.72663H16.4993C17.1744 7.21145 17.1715 5.75132 15.9489 4.54323C14.7292 3.33515 13.2749 3.31197 11.7539 4.01596V1.02327C11.8669 1.01748 11.9654 1.00879 12.061 1.00879C17.5568 1.00879 23.0525 1.00879 28.5483 1.00879C30.1041 1.00879 31.2194 1.90688 31.4975 3.37281C31.5207 3.48869 31.5294 3.61037 31.5294 3.72625C31.5294 5.32544 31.5294 6.92464 31.5294 8.52383C31.5294 8.58177 31.5236 8.63971 31.5178 8.72663H31.5207Z"
      stroke="#232323"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.7451 8.72295C11.7683 7.92335 11.8118 7.16142 12.087 6.43715C12.368 5.70418 13.2603 5.31887 14.0425 5.57961C14.7871 5.82876 15.1608 6.5878 14.9232 7.3845C14.7552 7.94074 14.4162 8.34053 13.8426 8.51726C13.1705 8.72295 12.4839 8.76061 11.7451 8.72295Z"
      stroke="#232323"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.76391 8.7259C9.00488 8.75777 8.2835 8.73459 7.594 8.48254C6.57422 8.10882 6.1976 6.74719 6.88131 5.98236C7.61717 5.16248 9.0802 5.43191 9.45682 6.47196C9.71466 7.18754 9.78419 7.93499 9.76102 8.72879L9.76391 8.7259Z"
      stroke="#232323"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "text-maizal-black",
    },
    size: {
      type: String,
      default: "w-6 h-6",
    },
  },
};
</script>
    