<template>
  <div class="overscroll-none relative bg-maizal-bg-yellow">
    <div v-if="showHeader">
      <MobileHeader class="flex md:hidden"/>
      <Header class="hidden md:flex"/>
    </div>
    <div class="min-h-screen" :class="`${bgColor ?`bg-${bgColor}`:'bg-maizal-bg-yellow'}`">
      <slot />
    </div>
    <div v-if="(this.$route.name !== 'checkout')&&(showFooter)" class="hidden sm:flex">
      <Footer />
    </div>
    <div v-if="(this.$route.name !== 'checkout')&&(showFooter)" class="flex sm:hidden">
      <FooterMobile />
    </div>
  </div>
</template>

<script>
import Header from "@/components/header/Header.vue";
import Footer from "@/components/footer/FooterDesktop.vue";
import FooterMobile from "@/components/sideBar/SideBarFooter.vue";
import MobileHeader from "@/components/header/MobileHeader.vue";

export default {
  props: {
    img: {
      type: String,
    },
    type: {
      type: String,
    },
    bgColor:{
        type:String
    },
    showFooter:{
      default:true,
      type: Boolean
    },
    showHeader:{
      default:true,
      type: Boolean
    },
  },
  components: {
      Header,
      Footer,
      MobileHeader,
      FooterMobile
  },
};
</script>