const getSavedState = (key) => {
    return JSON.parse(window.sessionStorage.getItem(key));
}

const saveState = (key, state) => {
    window.sessionStorage.setItem(key, JSON.stringify(state));
}

module.exports = {
    getSavedState,
    saveState,
}