<template>
    <Layout :footerMobile="footerMobile">
        <div class="text-maizal-gray flex flex-col justify-center items-center">
        <div class="flex flex-col justify-center items-center mt-28">
            <img loading=”lazy” src="https://maizalblobstorage.blob.core.windows.net/assets/404_Maize.png" alt="">
            <p class="pt-8 text-4xl text-maizal-gray font-roboto">Oh no.</p>
            <p class="text-base font-roboto">We can't find what you're looking for.</p>
             <div class="w-48 h-16 mt-6">
                <router-link to="/">
                    <OrderNowButton imagesrc="https://maizalblobstorage.blob.core.windows.net/assets/yellow_big_brush 2.png" text="back to home"/>
                </router-link>
            </div>
        </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "../layout/main.vue";
import OrderNowButton from "@/components/other/OrderNowButton.vue";

export default {
    data(){
        return {
            // Create an attribute inside the footMobile data :
            footerMobile :true,
        }
    },
    components: {
        Layout,
        OrderNowButton
    },
    props: {
        resource: {
            type: String,
            default: "",
        },
    },
};
</script>