<template>
  <svg
    class="fill-current"
    :class="color + ' ' + size + ' '"
    viewBox="0 0 48 57"
    xmlns="http://www.w3.org/2000/svg"
  >
<g clip-path="url(#clip0_411_30519)">
<path d="M9.06212 19.0392C9.66561 18.8262 10.0443 18.2937 10.4348 17.7968C11.0146 17.0749 11.6299 16.4004 12.2452 15.7141C13.4995 14.306 14.742 12.886 16.1146 11.5844C16.4578 11.2531 15.9371 10.7442 15.594 11.0637C14.245 12.2944 13.0025 13.6433 11.7601 14.9805C11.1684 15.6195 10.5531 16.2348 9.99693 16.8974C9.55911 17.4181 9.00295 17.9269 8.68346 18.5304C8.5533 18.7789 8.76629 19.1339 9.07395 19.0274L9.06212 19.0392Z"/>
<path d="M10.7672 20.6371C11.1222 20.2229 11.3588 19.7141 11.7257 19.3C12.1516 18.8148 12.6131 18.377 13.0273 17.88C14.0923 16.6138 15.4886 15.3832 16.2696 13.9277C16.4707 13.5491 15.9264 13.2177 15.6897 13.5846C15.1454 14.4366 14.2934 15.1465 13.5835 15.8684C13.0036 16.46 12.5066 17.1108 11.915 17.6907C11.3115 18.2941 9.64303 19.5603 10.1637 20.5661C10.2702 20.7673 10.6015 20.8264 10.7553 20.6371H10.7672Z" />
<path d="M13.9611 18.708C13.2274 19.4298 11.8193 20.3883 11.6418 21.4414C11.6063 21.6426 11.7601 21.8556 11.985 21.7846C12.5293 21.6189 12.7896 21.2521 13.1683 20.8261C13.6771 20.2699 14.1859 19.7374 14.6829 19.1813C15.5941 18.1873 16.4815 17.1578 17.2862 16.0692C17.511 15.7615 16.9904 15.3592 16.7537 15.6668C15.9017 16.7555 14.9314 17.7258 13.9493 18.6961L13.9611 18.708Z" />
<path d="M14.6704 21.2523C15.2147 20.6014 15.7472 19.9388 16.327 19.3116C16.8358 18.7792 17.4038 18.3177 17.7707 17.6668C18.0665 17.158 17.2855 16.6965 16.9778 17.2054C16.5164 17.939 15.7354 18.4952 15.1437 19.1223C14.5876 19.714 14.0787 20.3411 13.5344 20.9446C12.8718 21.6783 11.1086 23.1574 11.4755 24.2697C11.5228 24.4235 11.6885 24.5419 11.8541 24.4827C12.5049 24.246 12.7534 23.5242 13.1676 23.0154C13.6527 22.4119 14.1734 21.8439 14.6704 21.2523Z" />
<path d="M17.9729 19.4301C17.0381 20.3295 16.1387 21.2524 15.2394 22.1991C14.4939 22.9682 13.571 23.7019 13.1213 24.7077C12.9438 25.11 13.3698 25.7608 13.8195 25.4059C13.8431 25.3822 13.8786 25.3585 13.9023 25.3349C13.997 25.2639 14.0443 25.181 14.0798 25.0982C15.3341 23.2522 17.0144 21.6311 18.5764 20.0336C18.9669 19.6313 18.3634 19.0397 17.961 19.4183L17.9729 19.4301Z" />
<path d="M18.1385 23.3943C18.5645 22.8381 19.026 22.3293 19.4757 21.7968C19.7833 21.43 19.9372 21.0986 19.9845 20.6135C20.0318 20.1283 19.1917 19.9982 19.1207 20.4951C19.026 21.0986 18.3752 21.501 17.9965 21.9506C17.5824 22.4358 17.1801 22.9328 16.7541 23.4179C16.0441 24.2344 14.9081 25.2757 14.6596 26.3525C14.5649 26.7785 15.0028 27.0152 15.3223 26.8377C15.3223 26.8377 15.3223 26.8495 15.3223 26.8613C15.9021 26.5064 16.2216 25.9029 16.6239 25.3585C17.1209 24.6959 17.6415 24.0451 18.1504 23.3824L18.1385 23.3943Z" />
<path d="M21.1438 22.6012C21.5225 22.1516 20.8953 21.5126 20.493 21.9504C19.3215 23.2284 18.1264 24.4827 17.0496 25.8317C16.6354 26.3405 15.4403 27.4173 16.1503 28.0918C16.3869 28.3166 16.7301 28.2574 16.9076 27.9971C16.6946 28.3166 17.0259 27.8433 17.0851 27.7841C17.1916 27.6421 17.2744 27.4883 17.3809 27.3463C17.6294 26.9913 17.9134 26.6481 18.1737 26.2932C19.1085 25.0152 20.1262 23.7964 21.1557 22.6012H21.1438Z" />
<path d="M23.1086 23.3938C23.3216 22.8968 22.6116 22.4471 22.3631 22.956C21.949 23.8198 21.168 24.4824 20.5764 25.2161C20.0794 25.8432 19.5824 26.4586 19.0972 27.0857C18.6949 27.6064 17.3814 28.908 18.3281 29.4997C18.4937 29.6062 18.6831 29.547 18.8132 29.4405C19.1209 29.1802 19.2865 28.7187 19.5232 28.3873C19.7954 28.0087 20.0794 27.63 20.3515 27.2514C20.7893 26.6242 21.2272 25.9971 21.6887 25.3817C22.1975 24.7073 22.7773 24.1629 23.1205 23.3819L23.1086 23.3938Z" />
<path d="M25.0611 25.0624C25.3924 24.5417 24.5878 24.092 24.2446 24.5772C23.511 25.6303 22.73 26.648 21.9845 27.6893C21.5822 28.2336 20.955 28.7661 20.742 29.4169C20.5527 29.9968 21.2745 30.2689 21.665 29.9613C22.1856 29.5589 22.588 28.8134 22.9548 28.2691C23.6529 27.2041 24.3748 26.1392 25.0729 25.0624H25.0611Z" />
<path d="M25.7475 25.9737C24.6707 27.512 23.369 28.9202 22.375 30.5295C21.9609 31.2039 23.0969 31.7128 23.369 31.0738C24.4695 29.571 25.5818 28.0445 26.5995 26.4825C26.9545 25.9382 26.1025 25.4649 25.7356 25.9737H25.7475Z" />
<path d="M2.29406 50.2787C3.03954 50.1604 3.4892 49.2611 3.93886 48.7049C4.56601 47.9358 5.29966 47.285 5.97415 46.5513C6.66047 45.8176 7.38229 45.1077 8.09227 44.3858C8.77859 43.6877 9.55957 43.0842 10.1867 42.3387C10.3997 42.0784 10.0447 41.6997 9.80807 41.9601C9.15725 42.6819 8.34077 43.2499 7.61895 43.9244C6.8498 44.6462 6.03331 45.3443 5.29966 46.1135C4.64884 46.788 3.95069 47.3914 3.3472 48.1133C2.86205 48.6931 2.09289 49.2847 1.9864 50.0539C1.96273 50.2196 2.15206 50.3024 2.28222 50.2787H2.29406Z" />
<path d="M6.37701 48.2196C7.05149 47.5333 7.72598 46.847 8.38863 46.1488C8.99212 45.5098 9.65477 44.6579 10.4358 44.2437C10.8381 44.0307 10.4831 43.439 10.0808 43.6402C9.29978 44.007 8.68446 44.7289 8.05731 45.3087C7.21716 46.0897 6.41251 46.8943 5.59602 47.6871C4.77954 48.4918 3.98672 49.3319 3.2294 50.1957C2.75608 50.7282 1.9751 51.3909 2.23542 52.16C2.29459 52.3257 2.44842 52.3849 2.60225 52.3139C3.28857 51.9825 3.76189 51.0596 4.27072 50.4916C4.9452 49.7106 5.65519 48.9651 6.37701 48.2314V48.2196Z" />
<path d="M10.2455 46.3502C8.5652 47.9713 6.86124 49.6043 5.12177 51.1544C4.60111 51.6278 4.10412 52.1366 3.6308 52.6572C3.40597 52.9057 3.08648 53.2016 3.18114 53.5802C3.20481 53.6867 3.29947 53.7814 3.40597 53.8051C3.78463 53.8879 4.05679 53.5802 4.29345 53.3317C4.79044 52.8347 5.25193 52.3022 5.77259 51.8171C7.47656 50.2196 9.12136 48.5393 10.7543 46.8709C11.0856 46.5277 10.5768 46.0189 10.2337 46.3502H10.2455Z" />
<path d="M9.74879 49.1546C8.81398 50.0658 7.91466 51.0361 7.03901 51.9945C6.31719 52.7874 5.21672 53.6393 4.89722 54.6807C4.83806 54.87 5.03922 55.0948 5.24038 55.0238C6.26986 54.6215 6.95618 53.45 7.65433 52.6217C8.49448 51.6159 9.3583 50.6219 10.2576 49.6634C10.5771 49.3321 10.0801 48.8351 9.74879 49.1546Z" />
<path d="M39.473 10.2709C36.0059 2.65039 26.5158 -1.23086 18.5521 0.342939C13.7006 1.30142 9.66549 5.00518 7.92603 9.59642C6.94388 12.2115 6.68355 15.0278 7.08588 17.7849C7.21604 18.7197 7.4527 19.6782 7.76036 20.613C8.23369 22.5536 9.05017 24.3996 10.2808 26.0681C15.1087 32.6709 24.5634 36.3747 32.0419 31.8545C39.1062 27.5827 42.9993 18.0097 39.473 10.2827V10.2709ZM36.3964 18.6842C35.7575 21.9028 34.1482 24.7428 31.6987 26.9319C29.3321 29.05 26.4803 29.9612 23.2972 29.5943C20.4218 29.263 17.2268 27.7484 14.943 25.6421C12.2688 23.1926 10.7305 20.0332 10.482 16.4241C10.411 15.2881 10.411 14.093 10.5293 12.9097C10.5885 12.6257 10.6595 12.3535 10.7305 12.0695C11.6653 8.7326 14.3514 6.28315 17.4753 4.96968C23.1552 2.59123 30.7876 5.13534 34.4321 9.99874C35.438 11.3477 36.0651 12.6612 36.4083 14.2705C36.6923 15.584 36.6568 17.3826 36.4083 18.6961L36.3964 18.6842Z" />
<path d="M41.0112 38.067C38.6919 37.0257 36.1123 37.1795 33.6273 37.1914C27.9238 37.1914 22.2439 37.1914 16.5759 37.8185C14.1619 38.0788 11.7243 38.422 9.34584 38.8953C7.5827 39.2385 5.73674 39.7591 4.25761 40.8123C-0.428299 44.1019 -0.0733057 50.9414 1.33483 55.8758C1.63066 56.9053 2.9323 56.7633 3.27546 55.8758C4.7191 52.2431 2.35248 46.7762 5.91424 44.0427C6.92005 43.2736 8.30452 42.8949 9.64166 42.6346C11.5231 42.2678 13.4756 42.1021 15.4517 41.8773C20.4926 41.3093 25.5098 41.1436 30.5862 41.1436C32.9765 41.1436 35.5088 40.8951 37.8873 41.2383C39.4374 41.4631 40.6444 42.3388 41.4845 43.6404C43.4133 46.575 43.2476 50.4918 42.8572 53.8997C42.8217 53.9707 42.7862 54.0417 42.7625 54.1127C42.2418 55.0002 42.6797 56.2426 43.5316 56.7396C44.431 57.2603 45.4486 56.9763 46.0876 56.2426C46.4307 55.8995 46.6437 55.4143 46.7147 54.8345C47.2354 50.7403 47.3182 46.291 45.4131 42.5163C44.502 40.7058 42.8927 38.9072 41.023 38.0788L41.0112 38.067Z" />
</g>
<defs>
<clipPath id="clip0_411_30519">
<rect width="46.6342" height="57" fill="white" transform="translate(0.388672)"/>
</clipPath>
</defs>
</svg>


</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "text-maizal-black",
    },
    size: {
      type: String,
      default: "w-6 h-6 md:w-10 md:h-10",
    },
  },
};
</script>
