import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import ServerError from './PageNotFound.vue'
import store from "./state/store";
import { errorService } from "./notification/error.js";
import api from './api';
import VueTheMask from 'vue-the-mask'
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import VueScrollTo from "vue-scrollto";
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

Vue.prototype.$axios = api;

Vue.config.productionTip = false

Vue.use(VueScrollTo);
Vue.use(VueAwesomeSwiper);
Vue.use(VueTheMask);
new Vue({
    el: "#app",
    router,
    store,
    render: (h) => h(App),
});

// We need to evaluate this below: Why are we running line 44 when we have below?
Vue.config.errorHandler = (error) => errorService.onError(error);
Vue.config.performance = true;
api.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (!error.response) {
        return Promise.reject("Please check your internet connection");
    } else {
        // errorService.displayErrorAlert(error);
        // console.log('hi interceptor');
        // Vue.config.errorHandler = (error) => errorService.onError(error)
        if (error.response.status > 400 && error.response.status <= 500) {
            new Vue({
                el: "#app",
                router,
                store,
                render: (h) => h(ServerError),
            });
        }
        else {
            errorService.displayErrorAlert(error.response.data.message);
        }
        return Promise.reject(error);
    }
})

