<template>
  <div
    class="sticky top-0 h-16 w-full text-3xl px-4 z-10 flex items-center justify-between font-opensans bg-light-yellow"
  >
    <div class="flex items-center text-left justify-start space-x-4">
      <div>
        <FlowerIcon color="text-maizal-yellow" size="w-8 h-8" />
      </div>
      <div
        class="flex flex-col items-start justify-start w-full max-w-xs sm:max-w-sm"
        @click="setLocationPageOpen(true);"
      >
        <p
          v-if="orderTypeGlobal"
          class="font-normal text-xs sm:text-sm text-maizal-black font-opensans"
        >
          {{ orderTypeGlobal }}
        </p>
        <p
          v-else
          class="font-normal text-xs sm:text-sm text-maizal-black font-opensans"
        >
          find a maizal near me
        </p>

        <p
          v-if="Object.keys(this.location).length > 0 && orderTypeGlobal === 'pickup'"
          class="font-bold text-xs sm:text-sm text-maizal-black font-opensans w-full"
        >
          {{ location.name }}
        </p>
        <p
          v-else-if="Object.keys(this.location).length > 0 && orderTypeGlobal === 'delivery'"
          class="text-xs sm:text-sm text-maizal-black font-bold font-opensans"
        >
          {{ delivery.streetAddress }}
          {{ delivery.building ? delivery.building : "" }}, {{ delivery.city }}
          {{ delivery.state }},
          {{ delivery.zip }}
        </p>
      </div>
    </div>
    <div v-if="$route.name === 'account' && !isBag">
      <CloseButtonSideBarMenu
        color="text-maizal-icon"
        size="w-4 h-4"
        @click.native="routeTo()"
      />
    </div>
    <div v-else class="ml-auto" @click="closePage">
      <CloseButtonSideBarMenu color="text-maizal-icon" size="w-4 h-4" />
    </div>
  </div>
</template>

<script>
import { authComputed, userComputed, siteMethods } from "@/state/helpers";
import CloseButtonSideBarMenu from "@/components/icon/close.vue";
import FlowerIcon from "../icon/flower.vue";
export default {
  components: {
    CloseButtonSideBarMenu,
    FlowerIcon,
  },
  props: {
    isBag: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...authComputed,
    ...userComputed,
  },
  methods: {
    ...siteMethods,
    closePage() {
      this.$emit("closePage");
    },
    // Method to route to Menu page
    routeTo() {
      try {
        this.$router.go(-1);
      } catch (error) {
        if (
          error.name !== "NavigationDuplicated" &&
          !error.message.includes(
            "Avoided redundant navigation to current location"
          )
        ) {
          console.error();
        }
      }
    },
  },
};
</script>
