// import calculator from "@/_utils/calculator";
import cloneDeep from "lodash/cloneDeep";
// import appConfig from "@/app.config.json";
import storageHelper from "@/_utils/storage";

export const state = {
  selectedModifiers: storageHelper.getSavedState("BYO.selectedModifiers") || [],
  editModifiers: storageHelper.getSavedState("BYO.editModifiers") || [],
  selectedModifiersGroup:
    storageHelper.getSavedState("BYO.selectedModifiersGroup") || [],
  type: storageHelper.getSavedState("BYO.type") || "bowl",
  modifierCounter: storageHelper.getSavedState("BYO.modifierCounter") || {},
  modifierRules: {},
  selectedModifiersCheck:
    storageHelper.getSavedState("BYO.selectedModifiersCheck") || [],
  totalPrice: storageHelper.getSavedState("BYO.totalPrice") || 0,
  modifiersGroupForSelectedCYOItem:
    storageHelper.getSavedState("BYO.modifiersGroupForSelectedCYOItem") || [],
};

export const mutations = {
  SET_NO_MODIFIER(state, modifier) {
    console.log(modifier);
    console.log((state.selectedModifiersGroup.base = []));
    console.log(state.selectedModifiersGroup);
  },
  ADD_MODIFIER(state, { modifier, quantity, type, min, max }) {
    let index = find(modifier.id, state.selectedModifiers);

    if (index === -1) {
      if (!state.modifierCounter[type]) {
        state.modifierCounter[type] = 0;
      }
      state.modifierCounter[type]++;
      state.modifierCounter = cloneDeep(state.modifierCounter);
      let modifierDup = cloneDeep(modifier);
      modifierDup.quantity = quantity;
      modifierDup.type = type;
      if (modifier.isNoModifier === true) {
        const filteredRemovableData = state.selectedModifiers.filter((mod) => {
          return mod.type === type;
        });
        const data = state.selectedModifiers.filter(
          (e) => !filteredRemovableData.includes(e)
        );
        console.log(min);
        state.modifierCounter[type] = max;
        state.modifierCounter = cloneDeep(state.modifierCounter);
        state.selectedModifiers = data;
      } 
      state.selectedModifiers.push(modifierDup);
      let modifiersGroupForSelectedCYOItemDup = cloneDeep(
        state.modifiersGroupForSelectedCYOItem
      );
  
      for (let i = 0; i < modifiersGroupForSelectedCYOItemDup.length; i++) {
        if (modifiersGroupForSelectedCYOItemDup[i].type === modifierDup.type) {
          if (modifier.isNoModifier === true) {
            modifiersGroupForSelectedCYOItemDup[i].data = [];
            modifiersGroupForSelectedCYOItemDup[i].dataMobile = [];
            modifiersGroupForSelectedCYOItemDup[i].data.push(modifierDup);
            modifiersGroupForSelectedCYOItemDup[i].data.push(modifierDup.name);
          } else {
            modifiersGroupForSelectedCYOItemDup[i].data.push(modifierDup);
            modifiersGroupForSelectedCYOItemDup[i].dataMobile.push(modifierDup.name);
          }
        }
      }
      state.modifiersGroupForSelectedCYOItem =
        modifiersGroupForSelectedCYOItemDup;
      storageHelper.saveState(
        "BYO.modifiersGroupForSelectedCYOItem",
        state.modifiersGroupForSelectedCYOItem
      );
    }
    if (modifier.cost > 0) {
      if (index === -1) {
        state.totalPrice += modifier.cost;
        storageHelper.saveState("BYO.totalPrice", state.totalPrice);
      }
    }
    save(state);
  },
  REMOVE_MODIFIER(state, id, min, max) {
    const modifier = state.selectedModifiers[find(id, state.selectedModifiers)];
    if (modifier && modifier.cost > 0 && modifier.isNoModifier === false) {
      state.totalPrice -= modifier.cost;
      storageHelper.saveState("BYO.totalPrice", state.totalPrice);
    }
    if (modifier.isNoModifier === true) {
      state.modifierCounter[modifier.type] = min;
      console.log(max);
      state.modifierCounter = cloneDeep(state.modifierCounter);
    } else {
      state.modifierCounter[modifier.type]--;
      state.modifierCounter = cloneDeep(state.modifierCounter);
    }
    state.selectedModifiers = state.selectedModifiers.filter(
      (modifier) => modifier.id !== id
    );
    let modifiersGroupForSelectedCYOItemDup = cloneDeep(
      state.modifiersGroupForSelectedCYOItem
    );
    for (let i = 0; i < modifiersGroupForSelectedCYOItemDup.length; i++) {
      if (modifiersGroupForSelectedCYOItemDup[i].type === modifier.type) {
        const data = modifiersGroupForSelectedCYOItemDup[i].data.filter(
          (mod) => modifier.name !== mod.name
        );
        const dataMobile = modifiersGroupForSelectedCYOItemDup[i].dataMobile.filter(
          (mod) => modifier.name !== mod
        );
        modifiersGroupForSelectedCYOItemDup[i].data = data;
        modifiersGroupForSelectedCYOItemDup[i].dataMobile = dataMobile;
      }
    }
    state.modifiersGroupForSelectedCYOItem =
      modifiersGroupForSelectedCYOItemDup;

    storageHelper.saveState(
      "BYO.modifiersGroupForSelectedCYOItem",
      state.modifiersGroupForSelectedCYOItem
    );

    save(state);
  },

  CLEAR_MODIFIER(state) {
    state.selectedModifiers = [];
    state.modifierCounter = {};
    state.selectedModifiersGroup = [];
    state.totalPrice = 0;
    init();
  },
  CLEAR_EDIT_MODIFIER(state) {
    state.editModifiers = [];
    storageHelper.saveState("BYO.editModifiers", []);
  },
  ADD_EDIT_MODIFIER(state, modifier) {
    state.editModifiers.push(modifier);
    storageHelper.saveState("BYO.editModifiers", state.editModifiers);
  },
  ADD_MINMAX(state, { type, arr }) {
    state.modifierRules[type] = arr;
  },
  SET_TYPE(state, type) {
    state.type = type;
    storageHelper.saveState("BYO.type", state.type);
  },
  SET_MENUID(state, id) {
    state.menuId = id;
    storageHelper.saveState("BYO.menuId", state.menuId);
  },
  SET_PRICE(state, price) {
    state.totalPrice = price;
    storageHelper.saveState("BYO.totalPrice", state.totalPrice);
  },
  ADD_TEST(state, modifierGroup) {
    let index = findByType(modifierGroup.type, state.selectedModifiersCheck);
    if (index === -1) {
      state.selectedModifiersCheck.push(modifierGroup);
    }
    save(state);
  },
  ADD_MODIFIERS_GROUP_FOR_SELECTED_CYO_ITEM(state, data) {
    state.modifiersGroupForSelectedCYOItem = data;
    storageHelper.saveState(
      "BYO.modifiersGroupForSelectedCYOItem",
      state.modifiersGroupForSelectedCYOItem
    );
  },
};

export const getters = {
  isModifierEmpty(state) {
    return state.selectedModifiers.length === 0;
  },
  pickedModifiersString(state) {
    return state.selectedModifiers.map((modifier) => modifier.name).join(", ");
  },
  isModifierGroupFull(state) {
    return (type) => {
      return state.modifierCounter[type] >= state.modifierRules[type][1];
    };
  },
  isModifierGroupMin(state) {
    return (type) => {
      return state.modifierCounter[type] >= state.modifierRules[type][0];
    };
  },
  getModifierGroupMax(state) {
    return (type) => {
      return state.modifierRules[type][1];
    };
  },
  getModifierGroupMin(state) {
    return (type) => {
      return state.modifierRules[type][0];
    };
  },
  getModifierGroupCur(state) {
    return (type) => {
      return state.modifierCounter[type];
    };
  },
  getModifierRules(state) {
    return state.modifierRules;
  },
  isSelected(state) {
    return (id) => {
      return find(id, state.selectedModifiers) !== -1;
    };
  },
};

export const actions = {
  addModifier({ commit }, { modifier, quantity, type, min, max }) {
    commit("ADD_MODIFIER", { modifier, quantity, type, min, max });
  },
  removeModifier({ commit }, id, min, max) {
    commit("REMOVE_MODIFIER", id, min, max);
  },
  clearModifiers({ commit }) {
    commit("CLEAR_MODIFIER");
  },
  clearEditModifiers({ commit }) {
    commit("CLEAR_EDIT_MODIFIER");
  },
  addModifiersGroupForSelectedCYOItem({ commit }, modifierGroups) {
    commit("ADD_MODIFIERS_GROUP_FOR_SELECTED_CYO_ITEM", modifierGroups);
  },
  addEditModifier({ commit }, modifier) {
    commit("ADD_EDIT_MODIFIER", modifier);
  },
  addModifierMinMax({ commit }, { type, min, max }) {
    let arr = [min, max];
    commit("ADD_MINMAX", { type, arr });
  },
  setType({ commit }, type) {
    commit("SET_TYPE", type);
  },
  setMenuId({ commit }, id) {
    commit("SET_MENUID", id);
  },
  setPrice({ commit }, price) {
    commit("SET_PRICE", price);
  },
  setNoModifier({ commit }, modifier) {
    commit("SET_NO_MODIFIER", modifier);
  },
  removeModifiersInGroup({ commit }, { id, type }) {
    commit("REMOVE_MODIFIERS_IN_GROUP", { id, type });
  },
  addTest({ commit }, modifierGroup) {
    commit("ADD_TEST", modifierGroup);
  },
};

// Private helpers
function save(state) {
  storageHelper.saveState("BYO.selectedModifiers", state.selectedModifiers);
  storageHelper.saveState("BYO.modifierCounter", state.modifierCounter);
  storageHelper.saveState(
    "BYO.selectedModifiersCheck",
    state.selectedModifiersCheck
  );
}

function init() {
  storageHelper.saveState("BYO.selectedModifiers", []);
  storageHelper.saveState("BYO.modifierCounter", {});
  storageHelper.saveState("BYO.selectedModifiersCheck", []);
  storageHelper.saveState("BYO.selectedModifiersGroup", []);
  storageHelper.saveState("BYO.totalPrice", 0);
}

function find(id, state) {
  for (let i = 0; i < state.length; i++) {
    if (state[i].id === id) {
      return i;
    }
  }
  return -1;
}

function findByType(type, state) {
  for (let i = 0; i < state.length; i++) {
    if (state[i].type === type) {
      return i;
    }
  }
  return -1;
}
