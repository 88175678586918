<template>
  <button
    :disabled="isDisabled"
    :style="`width: ${buttonWidth}`"
    :class="
      `${cssClass} ${
        themeColor? `border-${themeColor} bg-${themeColor}`:''
      }`
    "
  >
    {{textToDisplay}}
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    cssClass: {
      type: String,
      default:
        "w-full font-semibold text-center cursor-pointer h-10 text-sm uppercase text-maizal-black",
    },
    isDisabled: {
      type: Boolean,
    },
    themeColor: {
      type: String,
      default:'maizal-black'
    },
    textToDisplay:{
        type: String,
        default:'textToDisplay'
    },
    buttonWidth: {
      type: String,
      default: "120px",
    },
  },
};
</script>
<style scoped>
.border-maizal-yellow-disabled {
  border-color: rgba(242, 205, 0, 0.3);
}
.bg-maizal-yellow-disabled {
  background-color: rgba(242, 205, 0, 0.3);
}
</style>
