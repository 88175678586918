import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Delete from "../assets/images/deleteIcon.png";

export class errorService {
  constructor() {
  }

  static onError(error) {
    const response = error.response;
    if (response && response.status >= 400 && response.status < 405) {
      // You can handle this differently
      errorService.sentryLogEngine(error);
      return false;
    }
    // Send Error to Log Engine e.g LogRocket
    errorService.logRocketLogEngine(error);
  }

  static onWarn(error) {
    // Send Error to Log Engine e.g LogRocket
    this.logRocketLogEngine(error);
  }

  static onInfo(error) {
    // You can handle this differently
    this.sentryLogEngine(error);
  }

  static onDebug(error) {
    const response = error.response;
    if (response && response.status >= 400 && response.status < 405) {
      // You can handle this differently
      this.sentryLogEngine(error);
      return false;
    }
    // Send Error to Log Engine e.g LogRocket
    this.logRocketLogEngine(error);
  }

  static initHandler() {
    const scope = this;
    window.onerror = (message, url, lineNo, columnNo, error) => {
      if (error) {
        scope.onError(error);
        console.log(message, url, lineNo, columnNo, error);
      }
    };
  }

  static displayErrorAlert(message) {
    Swal.fire({
      title: "Error!",
      text: message,
      backdrop: true,
      imageUrl: Delete,
      imageHeight: 32,
      imageWidth: 32,
      buttonsStyling: false,
      confirmButtonText: "Ok, I understand!",
      customClass: {
        title: "text-sm font-roboto",
        text: "text-sm font-roboto",
        icon: "w-4 h-4",
        confirmButton:
          "text-sm font-bold font-roboto focus:outline-none cursor-pointer rounded-lg h-10 py-1 border-0.5 hover:text-white px-2 hover:bg-maizal-yellow border-maizal-yellow text-maizal-yellow mb-4",
      },
    });
  }

  static displayErrorAlertWithTarget(message, target, containerStyle="") {
    //Algo
    // check if target is truthy
    // if so, create the object that sweetalert fires with "target" property,
    // if not, don't
    if(target){
      Swal.fire({
        toast: true,
        position: "top-end",
        width: "100%",
        title: message,
        background: "#F6F2EA",
        showConfirmButton: false,
        icon: "error",
        target: target,
        customClass: {
          container: "z-20" + " " + containerStyle,
          title: "text-sm font-brandon",
          text: "text-sm font-brandon",
          icon: "w-4 h-4",
        },
      });
    }else{
      Swal.fire({
        toast: true,
        position: "top-end",
        width: "100%",
        timer: 3000,
        timerProgressBar: true,
        title: message,
        background: "#F6F2EA",
        showConfirmButton: false,
        icon: "error",
        customClass: {
          container: "z-20" + " " + containerStyle,
          title: "text-sm font-brandon",
          text: "text-sm font-brandon",
          icon: "w-4 h-4",
        },
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
    }
   
  }

  static displayErrorAlertWithoutIcon(message) {
    Swal.fire({
      text: message,
      buttonsStyling: false,
      confirmButtonText: "Ok, I understand!",
      customClass: {
        title: "text-sm font-roboto",
        text: "text-sm font-roboto",
        icon: "w-4 h-4",
        confirmButton:
          "text-sm font-bold font-roboto focus:outline-none cursor-pointer rounded-lg h-10 py-1 border-0.5 hover:text-white px-2 hover:bg-maizal-yellow border-maizal-yellow text-maizal-yellow mb-4",
      },
    });
  }
  static displayErrorAlertWithHold(message,hold=false) {
    //Algo
    // By default hold is false
    // if hold =true it holds the message until error icon is clicked
    // else, timed error message is displayed for 3 sec
    if(hold){
      Swal.fire({
        toast: true,
        position: "top-end",
        width: "100%",
        title: message,
        background: "#F6F2EA",
        showConfirmButton: false,
        icon: "error",
        customClass: {
          container: "z-20" + " ",
          title: "text-sm font-brandon",
          text: "text-sm font-brandon",
          icon: "w-4 h-4",
        },
      });
    }else{
      Swal.fire({
        toast: true,
        position: "top-end",
        width: "100%",
        timer: 3000,
        timerProgressBar: true,
        title: message,
        background: "#F6F2EA",
        showConfirmButton: false,
        icon: "error",
        customClass: {
          container: "z-20" + " ",
          title: "text-sm font-brandon",
          text: "text-sm font-brandon",
          icon: "w-4 h-4",
        },
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
    }
   
  }

  static showErrorNotification(message, pos) {
    const Toast = Swal.mixin({
      toast: true,
      position: pos ? pos : "top-end",
      showConfirmButton: false,
      width: 480,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: "error",
      title: message,
    });
  }

  static logRocketLogEngine(error) {
    // Implement LogRocket Engine here
    console.log(error, "LogRocket");
  }

  static sentryLogEngine(error) {
    // Implement Sentry Engine here
    console.log(error, "Sentry");
  }
}
