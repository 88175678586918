<template>
  <nav
    class="flex items-center justify-between fixed z-20 top-0 w-screen font-roboto bg-white h-16 px-4"
    :style="this.currentRouteName === 'home' ? styleObject : ''"
  >
  <Modal
      :showing="locationPageOpen"
      @close="closeLocationPage"
      :showSemiBg="true"
    >
      <OrderType @close="closeLocationPage" />
    </Modal>
    <div
      class="flex sm:flex-row items-center pl-3 cursor-pointer justify-center"
      v-if="this.currentRouteName !== 'checkout'"
    >
      <router-link to="/" class="flex items-center justify-center">
        <img
          class="my-4 h-8"
          src="https://maizalblobstorage.blob.core.windows.net/banners/MaizalLogo.webp"
        />
      </router-link>
      <div class="border-maizal-black h-8 border-l-0.5 mx-3"></div>
      <div v-if="loggedIn" class="flex items-center">
        <router-link to="/account" class="text-maizal-yellow">
          <UserIcon />
        </router-link>
      </div>
      <div v-else>
        <User />
      </div>
    </div>
    <div v-else class="">
      <div class="flex items-center justify-start space-x-4">
        <div>
          <FlowerIcon color="text-maizal-yellow" size="w-10 h-10" />
        </div>
        <div class="flex flex-col items-start justify-start">
          <p
            v-if="orderTypeGlobal === 'pickup'"
            class="font-light text-sm text-maizal-black"
          >
            {{ orderTypeGlobal }}
          </p>
          <p
            v-else-if="orderTypeGlobal === 'delivery'"
            class="font-light text-sm text-maizal-black"
          >
            {{ orderTypeGlobal }}
          </p>
          <p
            v-if="Object.keys(this.location).length > 0 && orderTypeGlobal === 'pickup'"
            class="font-light text-xs sm:text-sm text-maizal-black font-bold w-full max-w-xxs"
          >
            <router-link to="/location" class="">
              <p class="truncate text-ellipsis">
                {{ Object.keys(this.location).length > 0 ? location.name.substring(0, 32) : "" }}
              </p>
              <span v-if="Object.keys(this.location).length > 0 && location.name.length > 32">...</span>
            </router-link>
          </p>
          <p
            v-else-if="Object.keys(this.location).length > 0 && orderTypeGlobal === 'delivery'"
            class="font-light text-xs sm:text-sm text-maizal-black font-bold"
          >
            <router-link to="/location" class="">
              {{ delivery.streetAddress }}, {{ delivery.state }},
              {{ delivery.zip }}
            </router-link>
          </p>
        </div>
      </div>
    </div>

    <div
      class="flex items-center space-x-4"
      v-if="this.currentRouteName !== 'checkout'"
    >
      <div
        class="flex items-center w-full justify-start max-w-xxxs"
        v-if="this.currentRouteName !== 'checkout'"
      >
      <LocationIcon
          v-if="Object.keys(this.location).length > 0"
          size="w-8 h-8"
          @click.native="openLocationPage"
        />
        <NoLocationIcon
        v-else
          size="w-8 h-8"
          @click.native="openLocationPage"
        />
      </div>
      <Bag
        class="cursor-pointer"
        :loggedIn="loggedIn"
        :username="loggedIn ? account.user.first_name : ''"
        :location="!isLocationEmpty ? location.name : 'find your location'"
      />
    </div>
    <div v-else><Close @click.native="routeTo" size="w-4 h-4"/></div>
  </nav>
</template>
<script>
import Bag from "./BagMobile.vue";
import { authComputed, userComputed, userMethods } from "@/state/helpers";
import UserIcon from "./UserIcon.vue";
import LocationIcon from "../icon/location.vue";
import NoLocationIcon from "@/components/icon/noLocation.vue";
import FlowerIcon from "../icon/flower.vue";
import Close from "../icon/close.vue";
import User from "./UserNotLoggedInPanel.vue";
import OrderType from "../../router/content/orderType.vue";
import Modal from "@/components/modal/modalFullRight.vue";
import { siteMethods, siteComputed } from '../../state/helpers';
import geoLocationHelper from "@/_utils/geo-location.js";
export default {
  components: {
    Bag,
    UserIcon,
    FlowerIcon,
    Close,
    User,
    LocationIcon,
    NoLocationIcon,
    OrderType,
    Modal,
  },
  computed: {
    ...authComputed,
    ...userComputed,
    ...siteComputed,
    currentRouteName() {
      return this.$route.name;
    },
  },

  data() {
    return {
      selectedOrderType: "",
      showModalLocationPickup: false,
      prevRoute: null,
      styleObject: {
        loading: "lazy",
        backgroundImage:
          "url(" +
          "https://maizalblobstorage.blob.core.windows.net/banners/maizal_new_banner_mobile_header.webp" +
          ")",
      },
      swiperOption: {
        slidesPerView: 1,
        loop: true,
        autoplay: {
          delay: 8000,
          disableOnInteraction: true,
        },
      },
    };
  },
  methods: {
    ...userMethods,
    ...siteMethods,
    // Method to route to Menu page
    sendToChooseLocation() {
      this.$router.push("/location");
    },
    closeLocationPage(){
        this.setLocationPageOpen(false)
    },
    openLocationPage(){
        this.setLocationPageOpen(true)
    },
    routeTo() {
      try {
        this.$router.go(-1);
      } catch (error) {
        console.log(error);
      }
    },
  },

  mounted() {
    this.selectedOrderType = this.orderTypeGlobal;
    const options = {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0,
    };
    geoLocationHelper
      .getPosition(options)
      .then((position) => {
        this.setLatitude(position.coords.latitude);
        this.setLongitude(position.coords.longitude);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>
