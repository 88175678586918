// import { v4 as uuidv4 } from "uuid";
// import cloneDeep from "lodash/cloneDeep";

export const state = {
    basketId: getSavedState("bag.basketId") || "",
    basketIdToTransferBasketContent:getSavedState("bag.basketIdToTransferBasketContent") || "",
    firstSelectedMealWithNoLocation: getSavedState("bag.firstSelectedMealWithNoLocation") || [],
    selectedMeals: getSavedState("bag.selectedMeals") || [],
    selectedUpsellsBag: getSavedState("bag.selectedUpsellsBag") || [],
    bagOpen: getSavedState("bag.bagOpen") || false,
};

export const mutations = {
    ADD_MEAL(state, meal ) {
        state.selectedMeals.push(meal);
        saveState("bag.selectedMeals", state.selectedMeals);
    },
    UPDATE_MEAL(state,meal) {
        let foundMeal = state.selectedMeals.find(
          (item) => item.uuid === meal.uuid
        );
  
        if (foundMeal) {
          Object.assign(foundMeal, meal);
        }
  
        saveState("bag.selectedMeals", state.selectedMeals);
      },
    ADD_FIRST_SELECTED_MEAL_WITH_NO_LOCATION(state, meal) {
        if (state.firstSelectedMealWithNoLocation.length > 0) {
            state.firstSelectedMealWithNoLocation.pop();
            state.firstSelectedMealWithNoLocation.push(meal);
          } else {
            state.firstSelectedMealWithNoLocation.push(meal);
        }
        saveState("bag.firstSelectedMealWithNoLocation", state.firstSelectedMealWithNoLocation);
      },
    RESET_SELECTED_MEAL(state) {
        state.selectedMeals = [];
        saveState("bag.selectedMeals", state.selectedMeals);
    },
    SET_BASKET_ID(state, id){
        state.basketId = id;
        saveState("bag.basketId", state.basketId);
    },
    SET_BASKET_ID_TO_TRANSFER_BASKET_CONTENT(state, id){
        state.basketIdToTransferBasketContent = id;
        saveState("bag.basketIdToTransferBasketContent", state.basketIdToTransferBasketContent);
    },
    SET_BAG_OPEN(state, isOpen){
        state.bagOpen = isOpen;
        saveState("bag.bagOpen", state.bagOpen);
    },
    REMOVE_MEAL(state, id) {
         state.selectedMeals = state.selectedMeals.filter((meal) => meal.uuid !== id);
        saveState("bag.selectedMeals", state.selectedMeals);
    },
    CLEAR_MEAL(state) {
        state.selectedMeals = [];
        saveState("bag.selectedMeals", state.selectedMeals);
    },
    CLEAR_FIRST_MEAL_WITH_NO_LOCATION(state) {
        state.firstSelectedMealWithNoLocation = [];
        saveState("bag.firstSelectedMealWithNoLocation", state.firstSelectedMealWithNoLocation);
    },
    REMOVE_UPSELL(state, { mealId, upsellId }) {
        const foundMeal = find(mealId, state.selectedMeals);
        const remainingUpsells = foundMeal.upsells.filter((item)=> item.product_id !== upsellId);
        foundMeal.upsells = remainingUpsells;
        saveState("bag.selectedMeals", state.selectedMeals);
    },
    ADD_UPSELL_BAG(state, upsell) {
        const foundUpsell = state.selectedUpsellsBag.find((item) => item.id === upsell.id);
        if (!foundUpsell) {
            state.selectedUpsellsBag.push({ ...upsell, count: 1 });
        } else {
            foundUpsell.count++;
        }
        saveState("bag.selectedUpsellsBag", state.selectedUpsellsBag);
    },
    REMOVE_UPSELL_BAG(state, upsellId) {
        state.selectedUpsellsBag = state.selectedUpsellsBag.filter(
            (upsell) => upsell.id !== upsellId
        );
        saveState("bag.selectedUpsellsBag", state.selectedUpsellsBag);
    },
    DECREMENT_UPSELL_BAG(state, upsellId) {
        const foundUpsell = state.selectedUpsellsBag.find((item) => item.id === upsellId);
        if(foundUpsell.count !== 0){
          foundUpsell.count--;  
        }
        else{
            foundUpsell.count = 0;
        }
        saveState("bag.selectedUpsellsBag", state.selectedUpsellsBag);
    },
};

export const getters = {
    isBagEmpty(state) {
        return (
            state.selectedMeals.length === 0
        );
    },
    isBagOpen(state) {
        return (
            state.bagOpen
        );
    },
    basketId(state){
        return (
            state.basketId
        );
    },
    basketIdToTransferBasketContent(state){
        return (
            state.basketIdToTransferBasketContent
        );
    },
    bagSize(state) {
        let size = 0;
        for(let meal of state.selectedMeals) {
            size += meal.quantity;
        }
        return size;
    },
    getTotalPrice(state) {
        let sum = 0;
        for (let meal of state.selectedMeals) {
            sum += getTotalMeal(meal);
        }
        for (let upsell of state.selectedUpsellsBag) {
            sum += upsell.price * upsell.count;
        }
        return sum;
    }
};

export const actions = {
    addMeal({ commit }, meal) {
        commit("ADD_MEAL", meal );
    },
    updateMeal({ commit }, meal) {
        commit("UPDATE_MEAL", meal );
    },
    addFirstSelectedMealWithNoLocation({ commit }, meal) {
        commit("ADD_FIRST_SELECTED_MEAL_WITH_NO_LOCATION", meal );
    },
    resetSelectedMeals({ commit }) {
        commit("RESET_SELECTED_MEAL");
    },
    setBasketId({ commit }, basketId) {
        commit("SET_BASKET_ID", basketId);
    },
    setBasketIdToTransferBasketContent({ commit }, basketIdToTransferBasketContent) {
        commit("SET_BASKET_ID_TO_TRANSFER_BASKET_CONTENT", basketIdToTransferBasketContent );
    },
    setBagOpen({ commit }, isOpen) {
        commit("SET_BAG_OPEN", isOpen);
    },
    removeMeal({ commit }, id) {
        commit("REMOVE_MEAL", id);
    },
    clearMeal({ commit }) {
        commit("CLEAR_MEAL");
    },
    clearFirstMealWithNoLocation({ commit }){
        commit("CLEAR_FIRST_MEAL_WITH_NO_LOCATION");
    },
    addUpsellToBag({ commit }, upsell) {
        commit("ADD_UPSELL_BAG", upsell)
    },
    removeUpsellFromMeal({ commit }, { mealId, upsellId }) {
        commit("REMOVE_UPSELL", { mealId, upsellId });
    },
    removeUpsellFromBag({ commit }, upsellId) {
        commit("REMOVE_UPSELL_BAG", upsellId);
    },
    decrementUpsellFromBag({ commit }, upsellId) {
        commit("DECREMENT_UPSELL_BAG", upsellId);
    },
};

// Private helpers

function getSavedState(key) {
    return JSON.parse(window.localStorage.getItem(key));
}

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state));
}

function find(id, state) {
    return state.find((item) => item.uuid === id);
}

function getTotalMeal(meal) {
    let sum = 0;
    for (let upsell of meal.upsells) {
        sum += upsell.price * upsell.count;
    }
    return sum + meal.price;
}