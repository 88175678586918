<template id="servererror">
        <div class="text-maizal-gray flex flex-col justify-center items-center">
        <div class="flex flex-col justify-center items-center mt-28 text-center px-4">
            <img loading=”lazy” class="h-60 md:h-80" src="https://maizalblobstorage.blob.core.windows.net/assets/Group 1837.svg" alt="">
            <p class="pt-8 text-4xl text-maizal-gray font-brandon">OOPS!.</p>
            <p class="text-base font-brandon">You broke the internet!</p>
            <p class="text-sm font-roboto">server error.we apologize and are fixing the problem</p>
            <p class="text-xs font-roboto">Please try again at a later stage</p>
            <div class="w-48 h-16 mt-6" @click="refreshPage()">
                <Button themeColor="maizal-yellow" textToDisplay="restart application"/>
            </div>
        </div>
        </div>
</template>

<script>
import Button from "@/components/other/displayButton.vue";

export default {
    name: 'ServerError',
    data(){
        return {
            // Create an attribute inside the footMobile data :
            footerMobile :true,
        }
    },
    components: {
        Button
    },
    props: {
        resource: {
            type: String,
            default: "",
        },
    },
    methods:{
        refreshPage(){
            window.location.reload()
        }
    }
};
</script>