<template>
  <svg
    class="fill-current cursor-pointer"
    :class="color + ' ' + size + ' '"
    viewBox="0 0 31 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5743 0.428467C15.0607 0.428467 15.5465 0.428467 16.0328 0.428467C16.2807 0.465553 16.5281 0.506514 16.7766 0.539172C18.4353 0.757261 19.9662 1.32739 21.3349 2.26672C23.9102 4.03302 25.4589 6.45414 25.9509 9.52067C25.9946 9.79245 26.0328 10.0648 26.0737 10.3371V11.7763C26.0558 11.8366 26.0333 11.8953 26.0216 11.9567C25.8791 12.6697 25.7832 13.3948 25.5891 14.0939C25.0152 16.1558 24.0145 18.0306 22.887 19.84C21.0583 22.7743 18.8723 25.4373 16.4883 27.9447C16.1994 28.2486 15.8499 28.4961 15.5285 28.7695H15.0798C14.8565 28.5951 14.608 28.4435 14.4139 28.2414C13.7963 27.5993 13.1832 26.9517 12.5953 26.2831C10.0829 23.4258 7.80878 20.4046 6.13548 16.98C5.50105 15.6814 5.00013 14.3347 4.72527 12.9138C4.65234 12.5363 4.59737 12.1554 4.53455 11.7757C4.53455 11.2958 4.53455 10.8165 4.53455 10.3366C4.55194 10.2474 4.57101 10.1583 4.58615 10.0687C4.66525 9.5965 4.71685 9.11825 4.82568 8.65274C5.28341 6.69105 6.2387 4.98785 7.67808 3.56972C9.38055 1.89033 11.4353 0.869073 13.8305 0.539172C14.079 0.505407 14.3264 0.465553 14.5743 0.428467ZM15.3372 26.6429C15.3872 26.5543 15.4096 26.4895 15.4528 26.443C17.5934 24.131 19.5864 21.7043 21.2748 19.0474C22.3939 17.2855 23.3554 15.4484 23.9281 13.4324C24.4246 11.6861 24.3948 9.95131 23.8008 8.23649C23.0603 6.09822 21.686 4.45924 19.6941 3.3472C17.737 2.25455 15.6351 1.93959 13.4401 2.40234C11.1728 2.88058 9.34073 4.06346 8.00231 5.93604C6.51805 8.01287 6.00422 10.3161 6.53376 12.8141C6.92978 14.6823 7.75325 16.386 8.71247 18.0289C10.2444 20.6526 12.1191 23.0294 14.1262 25.31C14.5042 25.7395 14.8958 26.158 15.3372 26.6434V26.6429Z"
    />
    <path
      d="M20.4054 11.1877C20.4139 13.9923 18.1163 16.2963 15.308 16.2994C12.5018 16.3026 10.2005 14.0019 10.2026 11.1952C10.2048 8.39696 12.4853 6.11056 15.2867 6.09674C18.0897 6.08292 20.3969 8.3773 20.4054 11.1877ZM15.3091 14.5987C17.1772 14.5955 18.7036 13.0676 18.7047 11.1994C18.7057 9.33182 17.1799 7.80224 15.3122 7.79746C13.4345 7.79267 11.897 9.33236 11.9034 11.2101C11.9103 13.0782 13.4415 14.6019 15.3091 14.5987Z"
    />
    <path
      d="M15.3039 35.571C11.3523 35.571 7.62403 35.0096 4.80554 33.9904C3.37938 33.4745 2.24634 32.8626 1.438 32.1712C0.781851 31.6104 0 30.6887 0 29.4848C0 28.0138 1.14882 26.9359 2.11218 26.2906C3.29031 25.5016 4.97127 24.824 6.97296 24.3294C7.45549 24.2105 7.94647 24.4945 8.06879 24.9638C8.19112 25.4336 7.89912 25.9106 7.41659 26.0296C5.61952 26.4731 4.13925 27.0631 3.13473 27.7352C2.52763 28.1421 1.80327 28.7753 1.80327 29.4842C1.80327 30.4535 3.16066 31.5226 5.43406 32.345C8.05639 33.2935 11.5615 33.8155 15.3039 33.8155C19.0463 33.8155 22.5514 33.293 25.1737 32.345C27.4471 31.5226 28.8045 30.4535 28.8045 29.4842C28.8045 28.7545 28.0418 28.1048 27.402 27.6881C26.3467 27.0006 24.7977 26.4046 22.9234 25.9649C22.4392 25.8514 22.1416 25.3772 22.2582 24.9062C22.3749 24.4352 22.862 24.1458 23.3467 24.2592C27.9617 25.3426 30.6083 27.2467 30.6083 29.4842C30.6083 30.6882 29.8259 31.6093 29.1703 32.1707C28.362 32.8621 27.2289 33.4739 25.8028 33.9898C22.9843 35.0091 19.256 35.5705 15.3044 35.5705L15.3039 35.571Z"
      fill="#A6D12C"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "text-maizal-gray",
    },
    size: {
      type: String,
      default: "w-6 h-6",
    },
  },
};
</script>
