<script>
import axios from "axios";
export default {
    data() {
        return {
            offlineConfirmed: false,
        };
    },
    beforeCreate() {
        axios
            .head("/api/ping")
            .then(() => {
                window.location.reload();
            })
            .catch(() => {
                this.offlineConfirmed = true;
            });
    },
};
</script>

<template>
    <div v-if="offlineConfirmed">
        <h1>
            The page timed out while loading. Are you sure you're still
            connected to the Internet?
        </h1>
    </div>
</template>