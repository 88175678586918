<template>
  <div class="flex flex-col items-center justify-between mx-auto mb-20 w-full">
    <div
      class="flex flex-col justify-center text-center py-6 font-roboto w-full"
    >
      <div class="flex flex-col items-center justify-center py-2">
        <p class="font-brandon text-2xl text-maizal-black">
          get maizal rewards
        </p>
        <p
          class="flex font-opensans font-normal text-base w-full max-w-xs items-center justify-center pb-4"
        >
          join now and claim your first reward
        </p>
      </div>
      <div
        class="flex items-center justify-center space-x-2 py-2 px-4 font-opensans"
      >
        <img
          class="h-16 w-16"
          src="https://maizalblobstorage.blob.core.windows.net/icons/maizal_levesl-04.png"
        />

        <div class="flex flex-col items-start justify-start">
          <p class="flex text-left text-2xl font-brandon text-maizal-black">
            El Bronce
          </p>

          <p class="flex text-xs w-60 text-left">
            claim your $5 onboarding reward and keep getting more!
          </p>
        </div>
      </div>
      <div class="w-full justify-center items-center flex flex-col p-4">
        <div class="">
          <div v-for="(item, index) of rewards[3].rewards" :key="index">
            <div class="flex items-center py-2 font-bold text-xs justify-start">
              <span
                class="md:w-2 md:h-2 w-2 h-2 rounded-full border-0.5 border-maizal-black bg-maizal-black"
              >
              </span>
              <img :src="item.img" class="w-6 h-6 ml-3" />
              <div class="ml-4 text-left">
                <p class="w-full">{{ item.description }}</p>
                <p class="font-opensans font-normal">
                  you have $5 on anything you like
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col mt-6 items-center">
          <Button
            themeColor="maizal-yellow"
            bgColor="bg-light-yellow"
            @click.native="sendToRegisterPage()"
            ><p>join now</p></Button
          >
          <p
            class="flex flex-col font-opensans text-sm cursor-pointer text-center mt-2"
            @click="sendToLoginPage()"
          >
            <span>already a member?</span
            ><span class="underline font-bold"> sign in</span>
          </p>
        </div>
      </div>
      <div
        class="flex flex-col items-center justify-center xl:max-w-md lg:max-w-md md:max-w-sm w-full z-0"
      >
        <DownloadRewardApp class="mt-8"/>
      </div>
    </div>
  </div>
</template>

<script>
// import RecentsAndFavorites from "@/components/sideBar/SectionRecentsAndFavorites.vue";
import DownloadRewardApp from "@/components/sideBar/SectionDownloadRewardsApp.vue";
import appConfig from "@/app.config.json";
import Button from "../other/button.vue";

export default {
  props: {},
  computed: {},
  components: {
    DownloadRewardApp,
    Button,
  },
  data() {
    return {
      rewards: appConfig.rewards,
    };
  },
  methods: {
    sendToLoginPage() {
      this.$router.push("/login").catch((error) => {
        if (
          error.name !== "NavigationDuplicated" &&
          !error.message.includes(
            "Avoided redundant navigation to current location"
          )
        ) {
          console.error();
        }
      });
    },
    sendToRegisterPage() {
      this.$router.push("/register").catch((error) => {
        if (
          error.name !== "NavigationDuplicated" &&
          !error.message.includes(
            "Avoided redundant navigation to current location"
          )
        ) {
          console.error();
        }
      });
    },
  },
};
</script>
