const getPosition = (options) => {
    return new Promise(function(resolve, reject) {
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
};

const getNearestLocation = (curLatitude, curLongitude, locations) => {
    let nearest;
    let distance = Number.MAX_VALUE;

    for (let location of locations) {
        let newDistance = getDistance(curLatitude, curLongitude, location.latitude, location.longitude);
        if (distance > newDistance) {
            nearest = location;
            distance = newDistance;
        }
    }

    return nearest;
};

const sortLocationAsc = (locations, curLatitude, curLongitude) => {
    locations.sort((a, b) => {
        let distA = getDistance(curLatitude, curLongitude, a.latitude, a.longitude);
        let distB = getDistance(curLatitude, curLongitude, b.latitude, b.longitude);
        if(distA > distB) {
            return 1;
        }
        if(distA < distB) {
            return -1;
        }
        return 0;
    })

    return locations;
};

const getDistance = (lat1, lon1, lat2, lon2) => {
    let radlat1 = (Math.PI * lat1) / 180;
    let radlat2 = (Math.PI * lat2) / 180;
    let theta = lon1 - lon2;
    let radtheta = (Math.PI * theta) / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    
    if (dist > 1) {
        dist = 1;
    }

    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;

    return dist;
};

module.exports = {
    getPosition,
    getDistance,
    getNearestLocation,
    sortLocationAsc,
};
