<template>
    <Transition name="fade">
      <div
        v-if="showing"
        @click.self="close"
        class="fixed z-50 inset-0 w-full h-screen flex items-center justify-center max-h-screen"
        :class="`${showSemiBg? 'bg-semi-75': 'bg-transparent'}`"
      >
        <div
          class="ml-auto bg-maizal-bg-yellow text-sm font-normal max-h-screen overflow-y-auto xl:max-w-md lg:max-w-md md:max-w-sm w-full h-screen overflow-content relative"
        >
          <SideBarHeader @closePage="close" v-if="this.$route.name !== 'location'"/>
          <slot />
        </div>
      </div>
    </Transition>
  </template>
  
  <script>
  import SideBarHeader from "../sideBar/SideBarHeader.vue"
  export default {
    components:{
        SideBarHeader
    },  
    props: {
      showing: {
        required: true,
        type: Boolean,
      },
      showSemiBg:{
          default:true,
          type: Boolean,
      }
    },
    methods: {
      close() {
        this.$emit("close");
      },
    },
  };
  </script>
  <style scoped>
  .fade-enter-active,
  .fade-leave-active {
      transition: all 0.4s;
  }
  .fade-enter,
  .fade-leave-to {
      opacity: 0;
  }
  </style>
  