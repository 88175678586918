<template>
    <svg
      class="fill-current cursor-pointer"
      :class="color + ' ' + size + ' '"
      viewBox="0 0 28 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5497 31.532C18.8834 31.3901 18.3966 30.9642 17.8954 30.5354C16.3455 29.2115 14.5812 28.2554 12.6575 27.6036C11.8348 27.3255 11.1308 26.8764 10.4557 26.3404C9.13466 25.2917 7.83387 24.2111 6.35926 23.3767C5.94497 23.1421 5.51621 22.9335 5.09323 22.7162C4.48195 22.4004 4.35157 21.96 4.71081 21.3719C5.05557 20.807 5.57415 20.4999 6.22599 20.4507C7.06615 20.3898 7.85995 20.5492 8.59871 20.9779C9.34616 21.4096 10.0994 21.8297 10.8555 22.2469C11.461 22.58 11.771 22.4004 11.8811 21.7254C11.9912 21.0504 11.8492 20.4217 11.5914 19.8133C10.224 16.5917 8.85364 13.376 7.47753 10.1573C7.26604 9.66192 7.15596 9.17231 7.45725 8.67691C7.95555 7.85703 9.0941 7.77591 9.71698 8.50888C9.82417 8.63635 9.91688 8.78121 10.0067 8.92316C11.2466 10.9106 12.4866 12.898 13.7179 14.8883C13.8395 15.0824 13.9438 15.149 14.1901 15.0997C17.345 14.4711 20.4623 14.6101 23.5274 15.6241C23.8895 15.7429 24.1242 15.9747 24.269 16.3368C24.8832 17.8867 25.4076 19.4657 25.7089 21.1083C25.8248 21.7399 25.8885 22.383 25.9609 23.0233C26.0681 23.9967 26.3289 24.9267 26.7316 25.819C26.8185 26.016 26.9141 26.2072 27.0068 26.4013V26.9981C26.8301 27.4298 26.4969 27.7021 26.1116 27.9483C24.692 28.858 23.284 29.788 21.8732 30.715C21.3691 31.0482 20.8737 31.3959 20.2711 31.532H19.5555H19.5497Z"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.86001 13.9877C4.94504 13.9443 2.2015 12.4088 1.28312 9.43061C0.408203 6.59436 1.60759 3.49447 4.23815 1.90108C6.72964 0.391694 10.07 0.811773 12.1617 2.91216C14.3258 5.08208 14.5344 8.23411 13.3814 10.4011C13.0308 9.83619 12.6947 9.27996 12.3384 8.73241C11.9647 8.15878 11.6286 7.55039 11.1796 7.0434C10.209 5.9512 8.65621 5.63542 7.33803 6.18297C5.98509 6.74211 5.12755 8.0371 5.20867 9.52331C5.23474 10.0071 5.39408 10.4967 5.57081 10.9516C5.93584 11.8844 6.35302 12.7941 6.74702 13.7125C6.78469 13.8023 6.81945 13.8921 6.86001 13.9877Z"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </template>
  <script>
  export default {
    props: {
      color: {
        type: String,
        default: "text-maizal-black",
      },
      size: {
        type: String,
        default: "w-6 h-6",
      },
    },
  };
  </script>
      