<template>
    <button class="flex justify-center items-center cursor-pointer"
    :class="`text-${textColor ?textColor: 'white' }`"
    :fontclass="`text-${fontSize ?fontSize:'base'}`">
        <img
            :src="imagesrc"
        />
        <p class="absolute">{{text}}</p>
        <slot></slot>
    </button>
</template>

<script>
export default {
  props: {
    imagesrc: {
      type: String,
    },
    text: {
      type: String,
    },
     textColor: {
      type: String,
    },
    fontSize:{
      type: String,
    },
  },
};
</script>