import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress/nprogress";

import routes from "./routes";

import { storeService } from "@/_services/store";
import store from "@/state/store.js";
// import store from "@/state/store.js";

// import { siteComputed } from '../state/helpers';

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  // mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0, behavior: "smooth" };
    }
  },
});

router.beforeEach((routeTo, routeFrom, next) => {
  if (routeFrom.name !== null) {
    NProgress.start();
  }

  unlockBody();

  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);

  if (!authRequired) return next();

  // If auth is required...
});

router.afterEach(() => {
  NProgress.done();
});

router.beforeResolve(async (to, from, next) => {
  if(to.name === 'menu')  {
  try {
    let stores;
    if (store.state.site.allStores.length > 0) {
      stores = store.state.site.allStores;
    } else {
      stores = await storeService.getStores();
      store.dispatch("site/setAllStores", stores);
    }
    if (to.params.slug) {
      // Map the stores with just names and check if the values are the same!
      let storeName = stores.filter((store) => store.slug === to.params.slug);
      if (storeName.length > 0) {
        next();
      } else {
        next({ name: "404" });
      }
    } else if (to.params.vendorId) {
      let vendorId = stores.filter((store) => {
        return store.vendorId === Number(to.params.vendorId);
      });
      if (vendorId.length > 0) {
        next();
      } else {
        next({ name: "404" });
      }
    } else {
      next();
    }
  } catch (error) {
    console.log(error);
    next({ name: "404" });
  }
}else{
    next(); 
}
});

export default router;

function unlockBody() {
  const body = document.body;
  body.style.height = "";
  body.style.overflowY = "";
}
