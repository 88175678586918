<template>
  <svg
    version="1.1"
    id="L4"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 60 80"
    enable-background="new 0 0 0 0"
    xml:space="preserve"
  >
    <circle fill="#505050" stroke="none" cx="0" cy="50" r="12">
      <animate
        attributeName="opacity"
        dur="1s"
        values="0;1;0"
        repeatCount="indefinite"
        begin="0.1"
      />
    </circle>
    <circle fill="#505050" stroke="none" cx="40" cy="50" r="12">
      <animate
        attributeName="opacity"
        dur="1s"
        values="0;1;0"
        repeatCount="indefinite"
        begin="0.2"
      />
    </circle>
    <circle fill="#505050" stroke="none" cx="80" cy="50" r="12">
      <animate
        attributeName="opacity"
        dur="1s"
        values="0;1;0"
        repeatCount="indefinite"
        begin="0.3"
      />
    </circle>
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
svg {
  width: 50px;
  height: 20px;
  display: inline-block;
}
</style>
