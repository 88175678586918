// const { cloneDeep } = require("lodash");
const moment = require("moment");
const { storeService } = require("../_services/store");
const store = require("@/state/store.js");

const getTime = (time) => {
  let timeArray = time.split(/(\s+)/);
  const format = "hh:mm a";
  return moment(timeArray[2], format).format("hh:mm a");
};

const getDate = (date) => {
  let timeArray = date.split(/(\s+)/);
  return moment(timeArray[0]).format("MMMM Do YYYY");
};

const getDateForBag = (date) => {
    let timeArray = date.split(/(\s+)/);
    return moment(timeArray[0]).format("dddd MMMM Do, YYYY");
  };

const getOpeningHours = (openingHours) => {
  let parsedHours = [];

  for (let hour of openingHours) {
    parsedHours.push(
      formatHours(
        hour.openingHours,
        hour.closingHours,
        hour.day.charAt(0),
        hour.day.charAt(hour.day.length - 1)
      )
    );
  }
  return parsedHours;
};

const formatHours = (ds, de, ws, we) => {
  const days = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
  if (ws === we) {
    return `${days[ws - 1]}: ${moment(ds, "HH:mm:ss").format("ha")} - ${moment(
      de,
      "HH:mm:ss"
    ).format("ha")}`;
  } else {
    return `${days[ws - 1]} - ${days[we - 1]}: ${moment(ds, "HH:mm:ss").format(
      "ha"
    )} - ${moment(de, "HH:mm:ss").format("ha")}`;
  }
};

const getLocationByVendorId = async (id) => {
  const store = await storeService.getStoreById(id);
  return store;
};

const getTimeslots = async (id, earliestReadyTime, readyDay) => {
  try {
    let chosenDay = moment(readyDay, "dddd MMMM Do, YYYY").format("YYYYMMDD");
    const format = "hh:mm a";
    let timeslots = [];

    const resp = await storeService.getStoreHoursOnChosenDay(id, chosenDay);

    if (resp) {
      let chosenDayHours = resp.calendar.filter((dates) => {
        // If the hours are the same for pickup/delivery, i guess we can just leave
        return dates.type === "business";
      })[0];

      // Getting the first index since we only get it for a day
      let storeStartTime = chosenDayHours.ranges[0].start.split(" ", 2)[1];
      let storeEndTime = chosenDayHours.ranges[0].end.split(" ", 2)[1];
      let endTime = moment(storeEndTime, "HH:mm").format("HH:mm");

      if (moment(chosenDay).isSame(moment().format("YYYYMMDD"))) {
        let readyTime = earliestReadyTime
          ? moment(earliestReadyTime, "hh:mm a").format("HH:mm")
          : moment().format("HH:mm");
        let startTime = moment(storeStartTime, "HH:mm").format("HH:mm");
        let start = "";
        if (moment(readyTime, "HH:mm").isBefore(moment(startTime, "HH:mm"))) {
          start = moment(startTime, format);
          let end = moment(storeEndTime, format);
          const remainder = 15 - (start.minute() % 15);
          let now = moment(start).add(remainder, "minutes");
          while (now.isBefore(end)) {
            timeslots.push(now.format(format));
            now.add(15, "minutes");
          }
        } else if (
          moment(readyTime, "HH:mm").isAfter(moment(endTime, "HH:mm"))
        ) {
          chosenDay = moment(readyDay, "dddd MMMM Do, YYYY")
            .add(1, "days")
            .format("YYYYMMDD");
          let asapDay = moment(readyDay, "dddd MMMM Do, YYYY")
            .add(1, "days")
            .format("dddd MMMM Do, YYYY");
          await store.default.dispatch("user/setAsapDay", asapDay);
          const resp = await storeService.getStoreHoursOnChosenDay(
            id,
            chosenDay
          );
          let chosenDayHours = resp.calendar.filter((dates) => {
            // If the hours are the same for pickup/delivery, i guess we can just leave
            return dates.type === "business";
          })[0];
          let storeStartTime = chosenDayHours.ranges[0].start.split(" ", 2)[1];
          let storeEndTime = chosenDayHours.ranges[0].end.split(" ", 2)[1];
          start = moment(storeStartTime, format);
          let end = moment(storeEndTime, format);
          const remainder = 15 - (start.minute() % 15);
          let now = moment(start).add(remainder, "minutes");
          while (now.isBefore(end)) {
            timeslots.push(now.format(format));
            now.add(15, "minutes");
          }
        } else if (
          moment(readyTime, "HH:mm").isAfter(moment(startTime, "HH:mm"))
        ) {
          // when your current time is before your store opening hours - setting preorder for future time but same day
          // start = moment(moment(readyTime, 'HH:mm').format('YYYY-MM-DD hh:mm a'));
          start = moment(readyTime, format);
          let end = moment(storeEndTime, format);
          const remainder = 15 - (start.minute() % 15);
          let now = moment(start).add(remainder, "minutes");
          while (now.isBefore(end)) {
            timeslots.push(now.format(format));
            now.add(15, "minutes");
          }
        }
      } else {
        //when setting a future date - preorder
        let start = "";
        let startTime = moment(storeStartTime, "HH:mm").format(
          "YYYY-MM-DD HH:mm"
        );
        let endTime = moment(storeEndTime, "HH:mm").format("YYYY-MM-DD HH:mm");
        start = moment(startTime);
        let end = moment(endTime, format);
        const remainder = 15 - (start.minute() % 15);
        let now = moment(start).add(remainder, "minutes");
        while (now.isBefore(end)) {
          timeslots.push(now.format(format));
          now.add(15, "minutes");
        }
      }

      return timeslots;
    }
  } catch (e) {
    console.log(e);
  }
};

const getsplitDateTimeObj = (time, date) => {
  const splitdayChoosen = moment(date, "dddd MMMM Do, YYYY");
  const YMD = {
    year: splitdayChoosen.year(),
    month: splitdayChoosen.month() + 1,
    day: splitdayChoosen.date(),
  };

  const militaryTime = moment(time, ["hh:mm A"]).format("HH:m");
  const HM = {
    hour: militaryTime.split(":")[0],
    minute: militaryTime.split(":")[1],
  };

  return { ...YMD, ...HM };
};

const getDayAndDate = async (numOfDays, id) => {
  let days = [];
  let startingIndex = 0;
  let currentDay = moment().format("dddd MMMM Do, YYYY");
  let currentTime = moment().format("HH:mm");
  let chosenDay = moment(currentDay, "dddd MMMM Do, YYYY").format("YYYYMMDD");
  const format = "hh:mm a";
  const resp = await storeService.getStoreHoursOnChosenDay(id, chosenDay);
  if (resp) {
    let chosenDayHours = resp.calendar.filter((dates) => {
      // If the hours are the same for pickup/delivery, i guess we can just leave
      return dates.type === "business";
    })[0];
    // let storeStartTime = chosenDayHours.ranges[0].start.split(" ", 2)[1];
    let storeEndTime = chosenDayHours.ranges[0].end.split(" ", 2)[1];
    let endTime = moment(storeEndTime, format);

    if (moment(currentTime, "HH:mm a").isAfter(moment(endTime, "HH:mm a"))) { 
      startingIndex = 1;
    }
  }

  for (let i = startingIndex; i < numOfDays; i++) {
    days.push(moment().add(i, "days").format("dddd MMMM Do, YYYY"));
  }
  return days;
};

module.exports = {
  getOpeningHours,
  getTimeslots,
  getTime,
  getDate,
  getDayAndDate,
  getLocationByVendorId,
  getsplitDateTimeObj,
  getDateForBag
};
