<template>
    <div class="flex justify-between items-center ">
        <div @click="toggle" class="relative flex items-center">
            <UserIcon :color="$route.name === 'home'? 'text-maizal-icon':'text-maizal-black'" size="h-8"/>
            <span
            v-if="loggedIn"
            class="absolute w-4 h-4 rounded-full -right-2 top-5 bg-maizal-green"
          ></span>
        </div>
        <slot></slot>
    </div>
</template>
<script>
import UserIcon from "../icon/user.vue";
import { authComputed } from "@/state/helpers";
export default {
    components: {
        UserIcon,
    },
    computed: {
    ...authComputed,
  },
    props: {
       
    },
    methods: {
        toggle() {
            this.$emit("toggle");
        },
    },
};
</script>