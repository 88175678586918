<template>
  <button
    :disabled="isDisabled"
    :style="`width: ${buttonWidth}`"
    :class="`${cssClass}
      ${margin}`"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    cssClass: {
      type: String,
      default:
        "text-sm font-bold font-opensans focus:outline-none h-10 py-1 bg-maizal-yellow text-maizal-black hover:text-white",
    },
    isDisabled: {
      type: Boolean,
    },
    margin: {
      type: String,
    },
    themeColor: {
      type: String,
      default: "maizal-black",
    },
    borderColor: {
      type: String,
      default: "maizal-black",
    },
    bgColor: {
      type: String,
    },
    hoverBgColor: {
      type: String,
    },
    buttonWidth: {
      type: String,
      default: "120px",
    },
  },
};
</script>
