<template>
  <div class="font-light w-full my-5 bg-maizal-bg-yellow relative">
    <p class="mb-2 text-base font-bold text-maizal-black">choose time</p>
    <div v-if="loading" class="loading flex flex-col items-center w-full">
      <img
        src="https://maizalblobstorage.blob.core.windows.net/assets/loading_spinner.svg"
      />
    </div>
    <swiper
      ref="timeSwiper"
      class="swiper w-full"
      :options="swiperOption"
      v-if="pickupDatetimes"
    >
      <swiper-slide
        v-for="time of pickupDatetimes"
        :key="time"
        class="flex flex-col items-center justify-center"
      >
        <p
          class="flex flex-col text-center cursor-pointer rounded-lg py-1 px-2 border border-maizal-icon font-bold text-sm w-24 font-opensans"
          :class="selectedTime === time ? 'bg-light-yellow' : ''"
          @click="chooseTime(time)"
        >
          <!-- <p class="flex flex-row mx-auto"> -->
          {{ time }}
          <!-- </p> -->
        </p>
      </swiper-slide>
      <!-- <div class="swiper-button-prev bg-white z-20" slot="button-prev"></div>
      <div class="swiper-button-next bg-white z-20" slot="button-next"></div> -->
    </swiper>
    <div
      id="time-swiper-button-prev"
      class="-z-10 swiper-button-prev mt-2"
      slot="button-prev"
    ></div>
    <div
      id="time-swiper-button-next"
      class="-z-10 swiper-button-next"
      slot="button-next"
    ></div>
  </div>
</template>

<script>
import timeHelper from "@/_utils/time";
import { userComputed, userMethods } from "@/state/helpers";
// import WatchIcon from "@/components/icon/watch.vue";

export default {
  components: {
    // WatchIcon,
  },
  props: {
    color: {
      type: String,
      default: "",
    },
    earliestReadyTime: {
      type: String,
    },
    vendorId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      swiperOption: {
        slidesPerColumn: 3,
        slidesPerGroup: window.innerWidth < 412 ? 3 : 4,
        spaceBetween: 10,
        slidesPerColumnFill: "row",
        slidesPerView: window.innerWidth < 412 ? 3 : 4,
        height: 720,
        direction: "horizontal",
        navigation: {
          nextEl: "#time-swiper-button-next",
          prevEl: "#time-swiper-button-prev",
          hideOnClick: true,
        },
      },
      chosenTime: "", //time selected by user in future
      initialPickUpTime: "", //Intial pick up time of user
      timeslots: [],
      initialPickUpDay: null,
      weekdays: null,
      // vendorId: undefined,
      pickupDatetimes: null,
      loading: false,
    };
  },
  computed: {
    ...userComputed,
  },
  methods: {
    ...userMethods,
    chooseTime(time) {
      this.chosenTime = time;
      this.setSelectedTime(this.chosenTime);
      this.$emit('sendToScheduleOrder')
    },
  },
  async mounted() {
    this.loading = true;
    // To handle the date changes
    this.$watch(
      () => this.selectedDay,
      async () => {
        if (this.selectedDay) {
          this.timeslots = await timeHelper.getTimeslots(
            this.vendorId,
            this.earliestReadyTime,
            this.selectedDay
          );
        } else {
          this.timeslots = await timeHelper.getTimeslots(
            this.vendorId,
            this.earliestReadyTime,
            this.asapDay
          );
        }
        if (this.timeslots) {
          this.initialPickUpTime = this.timeslots[0];
          this.setAsapTime(this.initialPickUpTime);

          this.$refs.timeSwiper &&
            this.$refs.timeSwiper.swiperInstance &&
            this.$refs.timeSwiper.swiperInstance.slideTo(0);
          this.pickupDatetimes = this.timeslots;
        }
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );

    this.timeslots = await timeHelper.getTimeslots(
      this.vendorId,
      this.earliestReadyTime,
      this.asapDay
    );

    if (this.timeslots) {
      this.initialPickUpTime = this.timeslots[0];
      // this.setAsapTime(this.initialPickUpTime);
      this.pickupDatetimes = this.timeslots;
      this.loading = false;
    }
  },
  created() {
    this.setSelectedTime("");
  },
};
</script>

<style scoped>
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 20px;
  font-weight: 900;
  margin-top: 30px;
  color: #f2cd00;
}

.swiper-button-next:after {
  right: 0px;
}
.swiper-button-prev:after {
  left: 0px;
}

.swiper-button-next-time:after,
.swiper-button-prev-time:after {
  position: absolute;
  top: 20px;
}
</style>
