import axios from "axios";

const baseUrl = `${process.env.VUE_APP_BASE_URL}/accounts`;
const baseUrlPx = `${process.env.VUE_APP_BASE_URL}/accounts/px`;

export const userService = {
  createFavoriteOrder,
  getRecentOrders,
  getFavoriteOrders,
  deleteFavoriteOrder,
  getUserContactNumber,
  getUserBillingAccount,
  getUserDetails,
  getContactDetails,
  updateUserDetails,
  updateContactDetails,
  changePassword,
  createFavoriteStore,
  deleteUserFavoriteStore,
  postFeedback,

  deleteUserCreditCard,
  setUserDefaultCreditCard,

  registerPx,
  requestOloGuestToken,
  requestUserDetails,
  loginPx,
  updateUserInfoPx,
  updateUserAddressPx,
  addUserAddressPx,
  deleteUserAddressPx,
  userTransactionHistory,
  userAccountInformation,
  resetPasswordWithLinkPx,
  createReferralCode,
  sendReferralEmail,
  resetPasswordPx
};

function getRecentOrders(authToken) {
  return axios
    .get(`${baseUrl}/users/${authToken}/recentorders`, {})
    .then((res) => {
      return res;
    });
}

async function getFavoriteOrders(authToken) {
  try {
    let data = await axios.get(`${baseUrl}/users/${authToken}/faves`, {});
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
}

async function createFavoriteOrder(authToken, params) {
  try {
    let data = await axios.post(`${baseUrl}/users/${authToken}/faves`, params);
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
}

async function postFeedback(params) {
  try {
    let data = await axios.post(`${baseUrl}/users/feedback`, params);
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
}

async function createFavoriteStore(authToken, restaurantId) {
  try {
    let data = await axios.post(
      `${baseUrl}/users/${authToken}/favelocations/${restaurantId}`,
      {}
    );
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
}
async function deleteFavoriteOrder(authToken, faveId) {
  try {
    let data = await axios.delete(
      `${baseUrl}/users/${authToken}/faves/${faveId}`
    );
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
}

async function getUserContactNumber(authToken) {
  try {
    let data = await axios.get(
      `${baseUrl}/users/${authToken}/contactdetails`,
      {}
    );
    return data.data.getUserContactNumberRes.contactdetails;
  } catch (error) {
    throw error.response.data.message;
  }
}

async function getUserBillingAccount(authToken) {
  try {
    let data = await axios.get(
      `${baseUrl}/users/${authToken}/billingaccounts`,
      {}
    );
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
}

async function getUserDetails(authToken) {
  try {
    let data = await axios.get(`${baseUrl}/users/${authToken}`);
    return data.data.getUserDetailsRes;
  } catch (error) {
    throw error.response.data.message;
  }
}

async function getContactDetails(authToken) {
  try {
    let data = await axios.get(`${baseUrl}/users/${authToken}/contactDetails`);
    return data.data.getContactDetailsRes;
  } catch (error) {
    throw error.response.data.message;
  }
}

async function updateUserDetails(authToken, body) {
  try {
    let data = await axios.put(`${baseUrl}/users/${authToken}`, body);
    return data.data.updateUserDetailsRes;
  } catch (error) {
    throw error.response.data.message;
  }
}

async function updateContactDetails(authToken, body) {
  try {
    let data = await axios.put(
      `${baseUrl}/users/${authToken}/contactDetails`,
      body
    );
    return data.data.updateContactDetailsRes;
  } catch (error) {
    throw error.response.data.message;
  }
}

async function changePassword(authToken, currentpassword, newpassword) {
  try {
    let data = await axios.post(`${baseUrl}/users/${authToken}/password`, {
      currentpassword,
      newpassword,
    });
    return data.data.changePasswordRes;
  } catch (error) {
    throw error.response.data.message;
  }
}
// olo

async function deleteUserFavoriteStore(authToken, id) {
  try {
    const res = await axios.delete(
      `${baseUrl}/users/${authToken}/favelocations/${id}`
    );
    return res;
  } catch (error) {
    throw error.response.data;
  }
}

async function registerPx(data) {
  try {
    const registerResponse = await axios({
      method: "POST",
      url: `${baseUrlPx}/create`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        registerObject: data
      },
    });
    return registerResponse.data.createPaytronixUserRes;
  } catch (error) {
    throw error.response.data;
  }
}

async function resetPasswordPx(data) {
  try {
    const resetPasswordResponse = await axios({
      method: "POST",
      url: `${baseUrlPx}/resetPassword`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        userObject: data
      },
    });
    return resetPasswordResponse.data.resetPasswordRes;
  } catch (error) {
    throw error.response.data;
  }
}


async function updateUserInfoPx(data) {
  try {
    const registerResponse = await axios({
      method: "POST",
      url: `${baseUrlPx}/editaccount`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        userObject: data
      },
    });
    return registerResponse.data.updateUserInformationRes;
  } catch (error) {
    throw error.response.data;
  }
}

async function updateUserAddressPx(data) {
  try {
    const updateUserAddressResponse = await axios({
      method: "POST",
      url: `${baseUrlPx}/editaddress`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        userObject: data
      },
    });
    return updateUserAddressResponse.data.updateUserAddressRes;
  } catch (error) {
    throw error.response.data;
  }
}

async function createReferralCode(data) {
  try {
    const createReferralCodeResponse = await axios({
      method: "POST",
      url: `${baseUrlPx}/createreferralcode`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        userObject: data
      },
    });
    return createReferralCodeResponse.data.createReferralCodeRes;
  } catch (error) {
    throw error.response.data;
  }
}

async function sendReferralEmail(data) {
  try {
    const sendReferralEmailResponse = await axios({
      method: "POST",
      url: `${baseUrlPx}/sendreferralemail`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        userObject: data
      },
    });
    return sendReferralEmailResponse.data.sendReferralEmailRes;
  } catch (error) {
    throw error.response.data;
  }
}

async function resetPasswordWithLinkPx(data) {
  try {
    const resetPasswordWithLinResponse = await axios({
      method: "POST",
      url: `${baseUrlPx}/resetpasswordwithlink`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        userObject: data
      },
    });
    return resetPasswordWithLinResponse.data.resetpasswordwithlinkRes;
  } catch (error) {
    throw error.response.data;
  }
}


async function addUserAddressPx(data) {
  try {
    const addUserAddressResponse = await axios({
      method: "POST",
      url: `${baseUrlPx}/addaddress`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        userObject: data
      },
    });
    return addUserAddressResponse.data.addUserAddressRes;
  } catch (error) {
    throw error.response.data;
  }
}

async function deleteUserAddressPx(data) {
  try {
    const deleteUserAddressResponse = await axios({
      method: "POST",
      url: `${baseUrlPx}/deleteaddress`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        userObject: data
      },
    });
    return deleteUserAddressResponse.data.deleteUserAddressRes;
  } catch (error) {
    throw error.response.data;
  }
}


async function loginPx(data) {
  try {
    const loginResponse = await axios({
      method: "POST",
      url: `${baseUrlPx}/authenticateUser`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        loginObject: data
      },
    });
    return loginResponse.data.authenticateUserRes;
  } catch (error) {
    throw error.response.data;
  }
}

async function requestOloGuestToken(data) {
  try {
    const OloGuestTokenResponse = await axios({
      method: "POST",
      url: `${baseUrlPx}/requestOloGuestToken`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        printedCardNumber: data
      },
    });
    return OloGuestTokenResponse.data.OloGuestTokenRes;
  } catch (error) {
    throw error.response.data;
  }
}

async function requestUserDetails(data) {
  try {
    console.log('data',data)
    const UserDetailsResponse = await axios({
      method: "POST",
      url: `${baseUrlPx}/requestUserDetails`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        username: data
      },
    });
    return UserDetailsResponse.data.userDetailsRes;
  } catch (error) {
    throw error.response.data;
  }
}

async function userTransactionHistory(data) {
  try {
    const userTransactionHistoryResponse = await axios({
      method: "POST",
      url: `${baseUrlPx}/transactionhistory`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: { data
      },
    });
    return userTransactionHistoryResponse.data.transactionHistoryRes;
  } catch (error) {
    throw error.response.data;
  }
}

async function userAccountInformation(data) {
  try {
    const userAccountInformationResponse = await axios({
      method: "POST",
      url: `${baseUrlPx}/accountinformation`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: { data
      },
    });
    return userAccountInformationResponse.data.accountInformationRes;
  } catch (error) {
    throw error.response.data;
  }
}

async function deleteUserCreditCard(authToken, id) {
  try {
    let data = await axios.delete(
      `${baseUrl}/users/${authToken}/billingaccounts/${id}`,
      {}
    );
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
}

async function setUserDefaultCreditCard(authToken, billingAccountId) {
  const body = { isdefault: true };
  try {
    let data = await axios.put(
      `${baseUrl}/users/${authToken}/creditcards/${billingAccountId}`,
      body
    );
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
}
