import store from "@/state/store.js";
export default [
  {
    path: "/",
    name: "home",
    component: () => import("./views/home.vue"),
  },
  {
    path: "/order",
    name: "order",
    component: require("./views/order.vue").default,
    // component: () => import("./views/order.vue"),
  },
  {
    path: "/location",
    name: "location",
    component: require("./views/order.vue").default,
    // component: () => import("./views/order.vue"),
  },
  {
    path: "/:slug/menu",
    name: "menu",
    component: () => lazyLoadView(import("./views/menu.vue")),
    props: () => ({
      location: store.state.user.location || {},
    }),
    beforeEnter(to, from, next) {
      if (
        store.state.user.location !== {} &&
        store.state.user.location !== null &&
        store.state.user.orderTypeGlobal !== null
      ) {
        next();
      } else {
        next("/");
      }
    }
  },
  {
    path: "/delivery/:vendorId/menu",
    name: "deliveryMenu",
    component: () => lazyLoadView(import("./views/menu.vue")),
    beforeEnter(to, from, next) {
        if (
          store.state.user.location !== {} &&
          store.state.user.location !== null &&
          store.state.user.orderTypeGlobal !== null
        ) {
          next();
        } else {
          next("/");
        }
      },
  },
  {
    path: "/checkout",
    name: "checkout",
    component: () => lazyLoadView(import("./views/checkout.vue")),
    beforeEnter(to, from, next) {
      if (
        store.state.bag.basketId !== "" &&
        store.state.bag.basketId !== null &&
        store.state.bag.selectedMeals !== [] &&
        store.state.bag.selectedMeals !== null &&
        store.state.user.location !== null &&
        store.state.user.orderTypeGlobal !== null
      ) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/order-status/:orderId",
    name: "order-status",
    component: () => lazyLoadView(import("./views/orderStatus.vue")),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("./views/register.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("./views/forgotPassword.vue"),
  },
  {
    path: "/account",
    name: "account",
    component: () => import("./views/account.vue"),
    beforeEnter(to, from, next) {
      if (store.getters["auth/loggedIn"] && store.state.auth.account) {
        next();
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/forget-to-scan",
    name: "forget-to-scan",
    component: () => import("./views/forgetToScan.vue"),
  },
  {
    path: "/giftcards",
    name: "giftcards",
    component: () => import("./views/giftcards.vue"),
  },
  {
    path: "/careers/:type/:careerGroup",
    name: "careergroup",
    component: () => lazyLoadView(import("./views/careerListings.vue")),
  },
  {
    path: "/talk-to-us/:id",
    name: "talk-to-us",
    component: () => import("./views/talkToUs.vue"),
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import("./views/faq.vue"),
  },
  {
    path: "/terms-conditions",
    name: "terms-conditions",
    component: () => import("./views/faq.vue"),
  },
  {
    path: "/all-locations",
    name: "locations",
    component: () => import("./views/locations.vue"),
  },
  {
    path: "/rewards",
    name: "rewards",
    // component: require("./views/rewards.vue").default,
    component: () => import("./views/rewards.vue"),
    props: () => ({
      account: store.state.auth.account || {},
      loggedIn: store.getters["auth/loggedIn"],
    }),
  },
  {
    path: "/privacy-policy",
    name: "privacyPolicy",
  component: () => import("./views/privacyPolicy.vue"),
  },
  {
    path: "/delete-account",
    name: "delete-account",
  component: () => import("./views/deleteAccount.vue"),
  },

  {
    path: "/404",
    name: "404",
    component: require("./views/_404.vue").default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
  },
  {
    path: "/loading",
    name: "loading",
    component: require("./views/_loading.vue").default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: "*",
    redirect: "404",
  },
];

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//

function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require("./views/_loading.vue").default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require("./views/_timeout.vue").default,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  });

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children);
    },
  });
}
