<template>
	<div class="flex flex-col items-center justify-center w-full">
		<div class="flex flex-col items-center justify-center mb-2">
			<div class="flex flex-row justify-center items-center text-center mx-auto space-x-2">
				<YellowFlowerIcon class="w-8 h-6"/>
				<p class="font-brandon font-bold text-2xl">download</p>
			</div>
			<div class="text-2xl font-bold text-center font-brandon text-maizal-yellow"> maizal reward app</div>
		</div>
		<div class="flex flex-row items-center justify-center space-x-2">
			<div class="">
                <img
					src="https://maizalblobstorage.blob.core.windows.net/assets/appStoreIcon.svg"
				/>
			</div>
			<div class="">
				<img
					src="https://maizalblobstorage.blob.core.windows.net/assets/googlePlay.svg"
				/>
			</div>	
		</div>
	</div>
</template>

<script>
import YellowFlowerIcon from "@/components/icon/flower.vue"
import { authComputed, userComputed } from "@/state/helpers";

export default {
  props: {
    
  },
   computed: {
    ...authComputed,
    ...userComputed,
  },
  components: {
	YellowFlowerIcon,

  },
   data() {
    return {
    
    };
  },
 
};
</script>