<template>
    <svg
      :class="color + ' ' + size"
      viewBox="0 0 27 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8783 30.5325C17.519 30.3615 17.3249 30.0544 17.154 29.7097C16.366 28.1076 15.5635 26.5113 14.7639 24.915C14.7175 24.8194 14.6712 24.7209 14.6104 24.5934C16.4152 24.4022 18.0869 23.8952 19.6542 23.0406C21.2244 22.1831 22.5571 21.0445 23.7101 19.6191C24.7183 21.65 25.7091 23.6461 26.6999 25.6451V25.9435C26.5203 26.5258 26.1118 26.7286 25.5237 26.717C24.275 26.6967 23.0235 26.717 21.7748 26.7054C21.5286 26.7054 21.3837 26.7836 21.2389 26.9806C20.5059 27.9801 19.7643 28.9738 19.0052 29.9559C18.8314 30.1819 18.5736 30.3442 18.3534 30.5354H17.8754L17.8783 30.5325Z"
        fill="#F2CD00"
      />
      <path
        d="M8.28051 30.5327C8.04585 30.301 7.77931 30.0924 7.57941 29.8345C6.84065 28.8756 6.11928 27.9022 5.4008 26.9259C5.28202 26.7636 5.15745 26.7057 4.95755 26.7057C3.63648 26.7144 2.31541 26.7144 0.994339 26.7057C0.371465 26.7057 -0.0428223 26.2972 0.00353113 25.7352C0.0151195 25.6019 0.058574 25.4628 0.119413 25.3411C1.04648 23.4754 1.97935 21.6155 2.91221 19.7527C2.9238 19.7266 2.94407 19.7063 2.98753 19.6426C4.12029 21.0361 5.45295 22.1775 7.01737 23.0322C8.5818 23.8897 10.2563 24.4025 12.0641 24.5879C12.0091 24.7067 11.9743 24.7965 11.9308 24.8805C11.1023 26.5405 10.2853 28.2035 9.43644 29.8519C9.30028 30.1155 9.02796 30.3068 8.81647 30.5327H8.28051Z"
        fill="#F2CD00"
      />
      <path
        d="M13.3276 22.8786C7.01483 22.8526 1.8812 17.6871 1.90438 11.3743C1.92755 5.07315 7.10465 -0.0488982 13.4435 0.000352221C19.6838 0.0496027 24.7885 5.09922 24.7682 11.4583C24.7479 17.8348 19.6345 22.8265 13.3276 22.8786ZM8.51553 16.815C8.50104 17.5219 9.09784 17.9362 9.85108 17.6349C10.9375 17.2004 12.0181 16.7571 13.1074 16.3312C13.2436 16.2791 13.4377 16.2762 13.5738 16.3312C14.6805 16.7629 15.7785 17.2177 16.8852 17.6523C17.5834 17.9246 18.215 17.4814 18.1686 16.7513C18.0962 15.5722 18.0151 14.3931 17.9195 13.214C17.9021 13.0112 17.934 12.8605 18.0701 12.7041C18.8234 11.8205 19.5708 10.9282 20.3096 10.033C20.86 9.36663 20.6167 8.66844 19.7736 8.45984C18.6495 8.17883 17.5255 7.9036 16.4043 7.611C16.2623 7.57334 16.1059 7.45456 16.0248 7.32998C15.4019 6.36235 14.7993 5.38314 14.1851 4.40682C13.7042 3.64199 12.9683 3.63909 12.4903 4.40392C11.8819 5.36865 11.2736 6.33628 10.6768 7.3068C10.5696 7.48063 10.445 7.57913 10.248 7.62548C9.13261 7.89781 8.02013 8.17883 6.90475 8.45695C6.04721 8.67133 5.81255 9.37532 6.37748 10.0503C7.11623 10.934 7.86369 11.8118 8.59085 12.7041C8.68646 12.82 8.75309 13.0025 8.7444 13.1502C8.68066 14.3409 8.59375 15.5287 8.50974 16.815H8.51553Z"
        fill="#F2CD00"
      />
      <path
        d="M10.3898 15.4876C10.4303 14.8358 10.4709 14.2245 10.5115 13.6132C10.5317 13.3061 10.5549 12.999 10.5752 12.6919C10.5926 12.3964 10.4738 12.156 10.2884 11.9358C9.76691 11.3216 9.25412 10.7045 8.73844 10.0845C8.68919 10.0266 8.64573 9.96286 8.5791 9.87305C9.43085 9.65867 10.2507 9.43849 11.0764 9.25018C11.4849 9.15747 11.7659 8.94888 11.9774 8.59254C12.4091 7.86827 12.8668 7.16138 13.339 6.40234C13.4346 6.5501 13.5128 6.66888 13.5882 6.79345C13.9735 7.40763 14.3646 8.01602 14.7354 8.63889C14.9324 8.96916 15.2047 9.15458 15.5756 9.24149C16.4041 9.43559 17.2269 9.64998 18.1018 9.86726C18.0323 9.96286 17.983 10.0411 17.9222 10.1135C17.4123 10.7248 16.9082 11.3361 16.3896 11.9416C16.155 12.2168 16.0768 12.5152 16.1086 12.8773C16.184 13.7262 16.2303 14.5779 16.2911 15.4731C16.155 15.4297 16.0565 15.4036 15.9609 15.3659C15.254 15.082 14.5442 14.801 13.8402 14.5055C13.4984 14.3606 13.1768 14.3635 12.8378 14.5055C12.1425 14.7981 11.4443 15.0733 10.7461 15.3543C10.6389 15.3978 10.5288 15.4355 10.3898 15.4847V15.4876Z"
        fill="#F2CD00"
      />
    </svg>
  </template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "text-maizal-black",
    },
    size: {
      type: String,
      default: "w-6 h-6",
    },
  },
};
</script>
    