<template>
  <svg
    class="fill-current cursor-pointer"
    :class="color + ' ' + size + ' '"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 24.5173C1 19.4253 1 14.3363 1 9.2443C1.20759 8.47859 1.75435 8.03977 2.40636 7.64808C5.86818 5.57771 9.32122 3.49262 12.7655 1.38985C13.6134 0.871523 14.397 0.868578 15.242 1.38985C16.9904 2.47068 18.7593 3.52207 20.5195 4.58523C22.3235 5.6749 24.1246 6.76751 25.9286 7.85129C26.5981 8.25476 26.9987 8.81727 26.9987 9.62126C26.9987 14.46 27.0016 19.2958 26.9987 24.1345C26.9987 25.4038 26.1449 26.52 24.9345 26.8793C24.8 26.9175 24.6684 26.9588 24.5368 27H18.9406C18.9172 26.9823 18.8938 26.9588 18.8675 26.9499C17.8559 26.6348 17.3793 25.984 17.3793 24.9179C17.3793 23.2421 17.3793 21.5664 17.3793 19.8907C17.3793 19.1397 16.9963 18.7568 16.2478 18.7568C14.7654 18.7568 13.2801 18.7568 11.7977 18.7568C11.0024 18.7568 10.6369 19.1249 10.6369 19.9201C10.6369 21.6371 10.6399 23.3511 10.6369 25.068C10.6369 25.7925 10.3095 26.3462 9.70132 26.7232C9.50835 26.841 9.28613 26.9087 9.07562 27H3.47941C3.41509 26.9794 3.35076 26.9529 3.28351 26.9352C2.23093 26.6731 1.52629 26.0252 1.15496 25.0033C1.09649 24.8442 1.0614 24.6764 1.01462 24.5144L1 24.5173Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "text-maizal-yellow",
    },
    size: {
      type: String,
      default: "w-6 h-6",
    },
  },
};
</script>
    