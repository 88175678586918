<template>
  <div
    class="xl:max-w-md lg:max-w-md md:max-w-sm h-16 sm:max-w-sm w-full flex flex-col items-center justify-center font-opensans px-4 relative"
  >
    <Modal :showing="showScanToPay" @close="showScanToPay = false">
      <div class="w-full max-w-sm">
        <div @click="showScanToPay = false" class="relative pt-4 pr-4">
          <Close size="w-4 h-4" class="ml-auto cursor-pointer" />
        </div>
        <div v-if="Object.keys(this.userPaymentORCode).length >0" class="flex flex-col items-center justify-center font-opensans w-full">
          <qrcode-vue :value="this.userPaymentORCode.data.qr_code.code" size="300" level="H" />
          <p class="font-brandon text-maizal-black mt-6 text-lg">Scan to Pay</p>
          <p>To pay in store,</p>
          <p>please scan this at checkout</p>
        </div>
      </div>
    </Modal>
    <div
      class="fixed bottom-0 xl:max-w-md lg:max-w-md md:max-w-sm h-20 sm:max-w-sm w-full flex flex-col items-center justify-center font-roboto px-4 z-10"
      style="
        background-image: url('https://maizalblobstorage.blob.core.windows.net/assets/maizal-catering-account-footer-bg.webp');
        background-repeat: no-repeat;
        background-size: cover;
      "
    >
      <div class="flex items-center justify-between text-xs w-full max-w-sm text-white">
        <router-link
          :to="$route.name !== 'home' ? '/' : ''"
          class="flex flex-col items-center space-y-1 cursor-pointer"
        >
          <HomeIcon color="text-maizal-yellow" size="w-6 h-6"/>
          <p>home</p>
        </router-link>
        <router-link
          to="/giftcards"
          class="flex flex-col items-center space-y-1 cursor-pointer"
        >
          <GiftcardsIcon color="text-maizal-yellow" size="w-6 h-6"/>
          <p>gift cards</p>
        </router-link>
        <!-- <div
          @click="showScanToPay = true"
          class="md:hidden flex flex-col items-center space-y-1 cursor-pointer"
        >
          <ScanIcon color="text-maizal-yellow" size="w-6 h-6"/>
          <p>scan to pay</p>
        </div> -->
        <router-link
          to="/all-locations"
          class="flex flex-col items-center space-y-1 cursor-pointer"
        >
          <LocationIcon color="text-maizal-yellow" size="w-6 h-6"/>
          <p>locations</p>
        </router-link>
        <div
          class="flex flex-col items-center space-y-1 cursor-pointer"
          @click="routeTo()"
        >
          <OrderNowIcon color="text-maizal-yellow" size="w-6 h-6"/>
          <p>order now</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authComputed, userComputed, siteMethods } from "@/state/helpers";
import OrderNowIcon from "@/components/icon/orderNowBottom.vue";
// import ScanIcon from "@/components/icon/forgetToScan.vue";
import HomeIcon from "@/components/icon/homeMain.vue";
import LocationIcon from "@/components/icon/location.vue";
import GiftcardsIcon from "@/components/icon/giftcards.vue";
import Close from "../icon/close.vue";
import QrcodeVue from 'qrcode.vue'
export default {
  components: {
    OrderNowIcon,
    // ScanIcon,
    HomeIcon,
    GiftcardsIcon,
    LocationIcon,
    Modal: () => import("@/components/modal/modalFull.vue"),
    Close,
    QrcodeVue
  },
  props: {},
  data() {
    return {
      showScanToPay: false,
    };
  },
  computed: {
    ...authComputed,
    ...userComputed,
  },
  methods: {
    ...siteMethods,
    closePage() {
      this.$emit("closePage");
    },
    // Method to route to Menu page
    routeTo() {
      try {
        if (this.location == null) {
          return "/";
        } else if (this.orderTypeGlobal === "delivery") {
          this.$router
            .push(`/delivery/${this.location.id}/menu`)
            .catch((error) => {
              if (
                error.name !== "NavigationDuplicated" &&
                !error.message.includes(
                  "Avoided redundant navigation to current location"
                )
              ) {
                console.error();
              }
            });
        } else {
          this.$router.push(`/${this.location.slug}/menu`).catch((error) => {
            if (
              error.name !== "NavigationDuplicated" &&
              !error.message.includes(
                "Avoided redundant navigation to current location"
              )
            ) {
              console.error();
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
