<template>
  <svg
    class="fill-current cursor-pointer"
    :class="color + ' ' + size + ' ' + margin"
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 10 11"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.55851 0.366117C10.0467 0.854272 10.0467 1.64573 9.55851 2.13388L6.61396 5.07842L9.55851 8.02297C10.0467 8.51113 10.0467 9.30258 9.55851 9.79074C9.07035 10.2789 8.2789 10.2789 7.79074 9.79074L4.8462 6.84619L2.13388 9.55851C1.64573 10.0467 0.854272 10.0467 0.366117 9.55851C-0.122039 9.07035 -0.122039 8.27889 0.366117 7.79074L3.07843 5.07842L0.366122 2.36612C-0.122034 1.87796 -0.122034 1.0865 0.366122 0.59835C0.854277 0.110194 1.64573 0.110194 2.13389 0.59835L4.8462 3.31066L7.79074 0.366117C8.27889 -0.122039 9.07035 -0.122039 9.55851 0.366117Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "text-maizal-icon",
    },
    size: {
      type: String,
      default: "w-6 h-5",
    },
    margin: {
      type: String,
      default: "ml-1",
    },
  },
};
</script>