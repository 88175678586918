<template>
  <svg
    class="fill-current cursor-pointer"
    :class="color + ' ' + size + ' '"
    viewBox="0 0 19 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
<g clip-path="url(#clip0_1379_81)">
<path d="M8.40512 0C8.84112 0 9.27712 0 9.71363 0C10.026 0.0483392 10.3394 0.0931537 10.6512 0.146025C14.1564 0.740698 17.0888 3.51315 17.8675 6.97897C17.9735 7.45078 18.0355 7.93216 18.1178 8.40901V9.71819C18.1021 9.79876 18.0794 9.87882 18.0714 9.95989C17.9008 11.681 17.2947 13.2339 16.2264 14.5904C14.6489 16.5934 13.0487 18.5789 11.4551 20.5693C10.189 22.1504 7.93026 22.1504 6.66464 20.5693C5.11845 18.6378 3.57276 16.7057 2.03262 14.7691C0.317372 12.6135 -0.303326 10.1719 0.14832 7.45531C0.725619 3.98396 3.55005 0.999514 6.97499 0.250256C7.44733 0.147032 7.92824 0.0825795 8.40512 0ZM16.1114 8.87528C16.0831 8.63761 16.0609 8.18242 15.9726 7.74031C15.148 3.60933 11.1205 1.16014 7.05623 2.3087C2.37577 3.63199 0.451604 9.4775 3.45064 13.3134C5.03114 15.3356 6.64799 17.3291 8.25776 19.3281C8.37787 19.4772 8.55752 19.6036 8.73616 19.6756C9.155 19.8442 9.57587 19.6952 9.89076 19.3014C11.4521 17.3492 13.0129 15.397 14.5707 13.4423C15.5845 12.1704 16.0806 10.7132 16.1114 8.87528Z" fill="#232323"/>
<path d="M8.10213 25.7808C7.67167 25.7305 7.23971 25.6897 6.81027 25.6288C5.05112 25.38 3.36615 24.9117 1.88858 23.8765C1.01607 23.2652 0.321195 22.5044 0.0819991 21.4258C-0.192016 20.1896 0.239444 19.1634 1.09682 18.2958C1.44703 17.9413 2.03139 17.964 2.40583 18.2893C2.7949 18.6271 2.87262 19.18 2.58397 19.608C2.49515 19.7394 2.38161 19.8543 2.28926 19.9837C1.9234 20.4978 1.91987 20.9817 2.28018 21.4973C2.56933 21.9117 2.96446 22.2083 3.39441 22.4595C4.45868 23.0829 5.62387 23.4142 6.83146 23.5955C8.95697 23.9153 11.0588 23.8135 13.1192 23.164C13.8903 22.9208 14.6311 22.6035 15.2593 22.0844C15.5132 21.8744 15.7418 21.6156 15.9249 21.3412C16.1833 20.9535 16.1646 20.529 15.9189 20.1302C15.8225 19.9741 15.7009 19.8341 15.5873 19.6886C15.2412 19.245 15.2936 18.6498 15.7125 18.2873C16.1258 17.9298 16.7344 17.967 17.1144 18.3819C17.6054 18.9177 17.9571 19.527 18.0747 20.2556C18.0797 20.2858 18.1024 20.3135 18.1166 20.3427V21.098C18.0918 21.2027 18.0661 21.3069 18.0419 21.4117C17.7951 22.4777 17.1245 23.241 16.2641 23.8523C14.7794 24.9067 13.0803 25.38 11.3055 25.6293C10.8766 25.6897 10.4451 25.731 10.0147 25.7808H8.10263H8.10213Z" fill="#F20000"/>
<path d="M9.0581 14.099C6.2806 14.099 4.02086 11.8326 4.0254 9.05155C4.03044 6.28363 6.28918 4.02931 9.05709 4.02881C11.8356 4.02881 14.0948 6.2942 14.0898 9.07572C14.0847 11.8436 11.8255 14.099 9.0581 14.099ZM9.04902 6.04294C7.38928 6.04596 6.03333 7.41306 6.03838 9.07874C6.04342 10.7268 7.40442 12.0838 9.05356 12.0848C10.7158 12.0858 12.0778 10.7238 12.0768 9.06112C12.0758 7.39896 10.7123 6.03942 9.04902 6.04294Z" fill="#232323"/>
</g>
<defs>
<clipPath id="clip0_1379_81">
<rect width="18.1163" height="25.7809" fill="white"/>
</clipPath>
</defs>
</svg>

</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "text-maizal-gray",
    },
    size: {
      type: String,
      default: "w-6 h-6",
    },
  },
};
</script>
