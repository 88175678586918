<template>
    <svg class="fill-current cursor-pointer" width="87" height="56" viewBox="0 0 87 56" :class="color + ' ' + size + ' '" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M85.7197 31.7057C84.5713 31.6721 83.612 30.9088 82.4568 31.0198C80.1228 31.2451 77.7787 31.0299 75.4311 31.2753C73.9686 31.4266 72.5567 31.6452 71.1921 32.1428C69.5775 32.7312 67.8988 33.2423 66.4228 34.0896C65.8418 34.4224 65.1764 34.6813 64.5954 35.0579L64.0887 35.3134L62.5857 36.2179L61.8189 36.7323L60.7718 37.4519L60.2213 38.0503L59.3566 39.3314L58.0156 40.4913L57.2387 42.1893C56.8233 42.3406 56.7118 42.6903 56.6544 43.0702L55.2155 44.5261L54.2866 47.3639L53.915 49.9259L54.2866 52.0105L55.1648 54.5961L55.1986 54.6062C55.5769 55.6183 55.864 55.7225 57.1441 55.6552C58.5358 55.5846 59.9139 55.2652 61.3224 55.4132C61.6737 55.4501 61.981 55.3257 62.2479 55.0904C62.8052 54.5961 63.5213 54.391 64.1799 54.0884C67.1253 52.7435 69.6147 50.8573 71.8508 48.4801C74.2388 45.9382 75.8027 42.7945 78.2515 40.3367C79.7073 38.8775 80.9031 37.1997 82.369 35.7438C83.4769 34.6443 84.4564 33.4406 85.8751 32.6808C86.3885 32.4051 86.2635 31.7225 85.7197 31.7057ZM60.7516 54.4549C60.7549 54.4583 60.7549 54.4583 60.7549 54.4583C60.7549 54.4583 60.7516 54.4583 60.7516 54.4549ZM72.2865 32.7985H72.2797C72.3 32.7884 72.3203 32.7783 72.3405 32.7682C72.3236 32.7783 72.3067 32.7884 72.2865 32.7985Z"/>
    <path d="M42.0436 0C41.5775 0.561501 40.7702 0.601851 40.3142 1.24741C38.4666 3.8599 36.9095 6.60689 36.2002 9.75398C35.9941 10.6719 35.8556 11.5932 35.5787 12.5077C35.099 14.1014 34.8626 15.7691 34.518 17.4065C34.4437 17.7629 34.4066 18.1126 34.9099 18.0992C37.0919 18.0454 39.2671 18.0151 41.4356 18.3547C41.7396 18.4018 42.3037 18.2169 42.2125 17.9008C41.8241 16.5492 42.6381 15.1841 42.2159 13.9299C41.8578 12.8708 41.8916 11.8588 41.9085 10.8165C41.9423 8.64443 42.0774 6.4724 42.0504 4.30036C42.0335 2.85122 41.8882 1.4088 42.3814 0.00336557C42.2699 3.28355e-06 42.1551 0 42.0436 0Z" />
    <path d="M45.7031 18.1615C47.3784 18.2389 49.0268 17.7547 50.7156 18.0304C51.4554 18.1514 51.8708 17.7681 51.6986 16.9309C51.3979 15.465 51.1683 13.9822 50.8406 12.523C50.1921 9.63479 48.8883 6.96176 47.9966 4.15089C47.5473 2.72865 46.4124 1.59893 45.3315 0.546532C44.6999 -0.0687658 44.2304 0.13633 44.1021 1.00044C44.0379 1.43417 44.0615 1.89144 44.0919 2.3319C44.2541 4.59808 43.8149 6.88779 44.4533 9.31872C43.4704 11.5378 44.7202 13.9957 44.0581 16.44C43.6866 17.8018 44.1933 18.0943 45.7031 18.1615Z" />
    <path d="M41.9872 46.6704C42.2675 43.288 41.9703 39.9122 41.9365 36.1767C41.7913 35.7598 41.896 34.9697 41.9534 34.1997C42.0176 33.3491 41.7575 32.959 40.8658 32.9624C38.7108 32.9658 36.5558 32.9422 34.4042 32.8884C33.5361 32.8683 33.4044 33.2616 33.4787 34.0249C33.7421 36.6744 33.9786 39.3306 34.85 41.8691C35.914 44.9758 37.5522 47.7127 40.251 49.7133C40.6057 49.9756 40.9874 50.2075 41.44 50.3151C42.0345 50.4564 42.3689 50.2647 42.2101 49.6595C41.9466 48.6676 41.9027 47.6556 41.9872 46.6704Z" />
    <path d="M52.0368 32.9307C51.7362 33.0484 51.3849 33.0618 51.0539 33.0686C49.1049 33.1123 47.156 33.4384 45.207 33.082C44.7105 32.9912 44.0518 33.1257 44.2072 33.5628C44.6159 34.7127 44.1295 35.7786 44.0856 36.8612C44.0113 38.6466 44.4437 40.3815 44.3862 42.1434C44.3018 44.6281 43.8593 47.0927 44.1228 49.6009C44.1971 50.3137 44.1599 50.4314 44.9267 50.1389C48.0815 48.9453 49.338 46.0302 50.5371 43.421C51.8679 40.5295 53.165 37.4194 52.6515 34.3227C52.736 33.8587 52.7562 33.6906 52.8002 33.5292C53.0231 32.7223 52.6921 32.6752 52.0368 32.9307Z" />
    <path d="M52.6818 25.4386C52.8169 23.7608 52.138 22.1705 52.1954 20.4927C52.2123 20.0388 51.8543 20.0153 51.4759 20.0556C49.4932 20.2641 47.5071 19.9413 45.5109 20.1935C44.0179 20.3817 44.126 20.2439 44.0618 21.73C43.9402 24.5342 44.5888 27.3652 43.7342 30.1458C43.5957 30.5997 43.697 31.0402 44.3827 31.0099C46.9329 30.9057 49.4797 30.8082 52.0299 30.7443C52.8946 30.7241 53.0027 30.2736 52.9453 29.5641C52.8304 28.1923 52.7662 26.8138 52.6818 25.4386Z" />
    <path d="M41.414 19.6633C39.1374 20.0802 36.8405 19.8718 34.5538 19.9457C34.0978 19.9592 33.9999 20.1677 33.9762 20.551C33.8445 22.649 33.2534 24.7034 33.3446 26.8216C33.3784 27.6588 33.429 28.4759 33.1386 29.2996C32.9697 29.7838 33.0609 30.4596 33.6689 30.4865C35.2767 30.5538 36.8473 31.2397 38.4821 30.7757C39.1914 30.5739 39.9177 30.5605 40.681 30.5437C41.0526 30.389 41.7923 31.2161 41.8666 30.0729C42.0726 26.9258 41.6639 23.7586 42.2314 20.6249C42.3395 20.0332 42.049 19.549 41.414 19.6633Z" />
    <path d="M27.3258 40.491L27.0691 40.2691C26.5928 39.7311 26.478 38.8099 25.7248 38.4299C25.6505 38.4333 25.5897 38.4064 25.5289 38.3694C25.6032 38.3929 25.6775 38.4165 25.7518 38.4367C24.9749 37.5154 24.1035 36.7252 22.9314 36.3319H22.928L22.9348 36.3285C22.9314 36.3285 22.928 36.3285 22.9246 36.3319C22.6814 35.4846 22.087 35.2257 21.2763 35.3265L21.2527 35.3131L21.2358 35.303C21.256 35.1046 21.1378 34.9903 20.9791 34.9029C19.4658 33.6319 17.6284 32.8317 15.7976 32.4182C13.771 31.9609 11.7275 31.4532 9.6299 31.1741C6.30284 30.7337 3.14466 30.9119 0 31.9609C0 32.1862 0 32.4081 0 32.6333C0.972785 32.7309 1.58415 33.4 2.18201 34.0489C5.15441 37.2699 8.04912 40.5549 10.6128 44.1155C11.4505 45.2789 12.376 46.3817 13.3015 47.4778C14.3992 48.779 15.9598 49.6431 16.8751 51.1259C17.0035 51.3344 17.2061 51.3781 17.4324 51.3579C17.4291 51.3713 17.4257 51.3814 17.4257 51.3949C17.8243 51.8185 18.287 52.1211 18.8984 52.1279C18.9119 52.1178 18.9254 52.111 18.9355 52.1043C19.4422 52.7835 20.0772 53.3618 20.8406 53.6779C23.5495 54.7874 26.1639 56.1962 29.3288 55.4599C30.3522 55.2212 30.9332 54.9455 31.2271 53.9637C32.2235 50.6518 31.9094 47.498 30.1259 44.5224C29.2849 43.1237 28.8694 41.4627 27.3258 40.491ZM16.1759 32.8855C16.1658 32.825 16.2806 32.8317 16.1759 32.8855V32.8855ZM18.9727 52.0976C19.0504 52.0875 19.0234 52.222 18.9727 52.0976V52.0976ZM20.8203 35.0912C20.8237 35.0878 20.8271 35.0845 20.8304 35.0811C20.8338 35.0912 20.8406 35.0979 20.8439 35.1046L20.8203 35.0912Z" />
    </svg>
    
    </template>
    
    <script>
    export default {
        props: {
            color: {
                type: String,
                default: "text-maizal-black",
            },
            size: {
                type: String,
                default: "w-6 h-6",
            },      
        },
    };
    </script>