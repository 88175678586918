<template>
  <UserIcon @toggle="toggleMenuPage">
    <Transition name="fade">
      <div
        ref="menuPage"
        v-if="isMenuPageOpen"
        @click.self="closePage"
        class="bg-semi-75 w-full h-screen absolute z-30 top-0 left-0"
      >
        <div
          class="mr-auto bg-white text-sm font-normal h-screen overflow-y-auto xl:max-w-md lg:max-w-md md:max-w-sm w-full pb-32 z-50 relative"
        >
          <CloseIcon class="mt-4 absolute right-4 top-4" @click.native="closePage" size="w-4 h-4" />
          <!-- <CardPaytronixMessage/> -->
          <SideBarMenuNotLoggedIn v-if="!loggedIn" :closePage="closePage" class="mt-10" />
          <SideBarFooter class="hidden" />
        </div>
      </div>
    </Transition>
  </UserIcon>
</template>
<script>
import {
  authComputed,
  userComputed,
  userMethods,
  siteComputed,
  siteMethods,
} from "@/state/helpers";
import CloseIcon from "@/components/icon/close.vue";
// import CardPaytronixMessage from "@/components/card/CardNotLoggedInTopBanner.vue";
import SideBarMenuNotLoggedIn from "@/components/sideBar/SideBarMenuNotLoggedIn.vue";
import SideBarFooter from "@/components/sideBar/SideBarFooterAccount.vue";

export default {
  components: {
    UserIcon: () => import("./UserIcon.vue"),
    SideBarMenuNotLoggedIn,
    // CardPaytronixMessage,
    CloseIcon,
    SideBarFooter
  },
  props: {},
  data() {
    return {
      page: "",
    };
  },
  computed: {
    ...siteComputed,
    ...authComputed,
    ...userComputed,
  },
  methods: {
    ...siteMethods,
    ...userMethods,
    closePage() {
      this.setMenuPageOpen(false);
      this.unlockBody();
    },
    toggleMenuPage() {
      this.setMenuPageOpen(!this.isMenuPageOpen);
      this.isMenuPageOpen ? this.lockBody() : this.unlockBody();
    },
    lockBody() {
      const body = document.body;
      body.style.height = "100vh";
      body.style.overflowY = "hidden";
    },
    unlockBody() {
      const body = document.body;
      body.style.height = "";
      body.style.overflowY = "";
    },
  },
  mounted() {
    this.setMenuPageOpen(false);
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
