<template>
  <div
    class="w-full flex flex-col items-start justify-start z-50 relative mb-10"
  >
    <div class="flex flex-col justify-center items-center w-full max-w-xl mt-8 px-4 bg-maizal-bg-yellow">
      <ChooseDay :earliestReadyDay="earliestReadyDay" />
      <ChooseTime
        :earliestReadyTime="earliestReadyTime"
        :vendorId="this.location.id"
        @sendToScheduleOrder="captureChoosenTime"
      />
    </div>
  </div>
</template>

<script>
// import Button from "../../components/other/button.vue";
import ChooseDay from "@/components/userOptions/editDay.vue";
import ChooseTime from "@/components/userOptions/editTime.vue";
import { userComputed, userMethods } from "@/state/helpers";

export default {
  components: {
    // Button,
    ChooseTime,
    // WatchIcon
    ChooseDay,
  },
  props: {
    earliestReadyTime: {
      type: String,
    },
    earliestReadyDay: {
      type: String,
    },
  },
  data() {
    return {
      timeChoosen: null,
    };
  },

  computed: {
    ...userComputed,
  },
  methods: {
    ...userMethods,
    captureChoosenTime(timeChoosen) {
      this.timeChoosen = timeChoosen;
      this.$emit("close");
    },
    updateTime() {
      this.setSelectedTime(this.timeChoosen);
      this.$emit("closeScheduleOrder");
    },
    setAsapTime() {
      this.setSelectedTime(this.asapTime);
      this.$emit("closeScheduleOrder");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
